let customComponentKeys = {
    DataErrorMessageComponentKey: "dataErrorMessageCustomComp",
    FormSubmissionComponentKey: "formSubmissionCustomComp",
    KendoGridComponentKey: "kendoGridCustomComp"
}

export const kendoUploadMetadataColumnTypes = {
    CONTEXT: "context",
    CUSTOM: "custom",
    PATH: "path",
    NAME: "name"
}

export const dataTypes = {
    boolean: "Boolean",
    dateTime: "DateTime",
    decimal: "Decimal",
    integer: "Integer",
    lookup: "Lookup",
    multiSelect: "Multiselect",
    select: "Select",
}

export default customComponentKeys;