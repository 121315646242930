import customComponentKeys from "../constants";
import settingsForm from "./FormSubmission.settingsForm";
import { name } from './Template'

import NestedComponent from 'formiojs/components/_classes/nested/NestedComponent';

export default class FormSubmission extends NestedComponent {
    static schema(...extend) {
        return NestedComponent.schema({
            type: customComponentKeys.FormSubmissionComponentKey,
            key: 'well',
            input: false,
            persistent: false,
            components: [],
            hidden: true,
        }, ...extend);
    }

    static get builderInfo() {
        return {
            title: 'Form Submission',
            icon: 'square-o',
            group: 'Beca',
            schema: FormSubmission.schema()
        };
    }

    get defaultSchema() {
        return FormSubmission.schema();
    }

    static editForm = settingsForm;

    get className() {
        return `${this.component.customClass}`;
    }

    get templateName() {
        return name;
    }

    constructor(...args) {
        super(...args);
        this.noField = true;
    }
}