import Button from '@material-ui/core/Button';
import FormTypes from '../../../enums/FormTypes';
import { getPassedContextParamString } from '../../../util/helpers'

export const CreateButton = ({ buttonSettings, tenant, addContext, tableName, onCreateClick }) => {
    if (!buttonSettings) return null;

    const addPath = `/${tenant}/form/${tableName}/${FormTypes.ADD}${getPassedContextParamString(addContext)}`;

    return (
        <a href={addPath} onClick={() => onCreateClick(`grid/${tableName}/create`)}>
            <Button variant="contained" color="primary">{buttonSettings.label}</Button>
        </a>
    );
}
