import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Components, FormBuilder, Templates } from 'react-formio';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import useThrowAsyncError from '../../../hooks/asyncErrorHook';
import FormOptions from '../CustomComponents';
import useAssetManagementFetch from './../../../hooks/fetchHook';
import useFetchPlugin from './fetchPluginHook';
import { useStyles } from './Styles';

Components.setComponents(FormOptions.Components);
Templates.extendTemplate('bootstrap', FormOptions.Templates)

var formData = {}

export default function Editor() {
    const classes = useStyles();

    const accountInfo = useSelector(state => state.user.accountInfo);
    const userState = accountInfo;

    const [jsonForm, setJsonForm] = useState();
    const { formId, groupName } = useParams();
    const history = useHistory();
    const plugin = useFetchPlugin({ tableId: jsonForm?.tableId });

    const { get, put } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();
    const { tenant } = useParams();

    if (!window.Formio.getPlugin(plugin.name) && jsonForm) {
        window.Formio.registerPlugin(plugin.plugin, plugin.name);
    }

    window.Formio.setProjectUrl(window.location.origin);

    const tenantPath = process.env.NODE_ENV === "development" ? "" : `/${tenant}`
    window.Formio.setApiUrl(`${process.env.REACT_APP_AMM_URL}${tenantPath}`);

    useEffect(() => {
        get(`form/config/${groupName}/${formId}Form`)
            .then(data => setJsonForm(data))
            .catch(error => throwAsyncError(error));
    }, [groupName, formId, get, throwAsyncError])

    if (!jsonForm) {
        return null;
    }

    return (
        <React.Fragment>
            <Box m={2}>
                <Box display="flex" mb={5}>
                    <Box flexGrow={1} display="inline">
                        <Typography variant="h3">Edit Form ({`${groupName}/${formId} on table: ${jsonForm.tableId}`})</Typography>
                    </Box >
                    <Box className={classes.buttons}>
                        <Button
                            variant="contained"
                            color="secondary"
                            href={`/${tenant}/admin/form/index`}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSaveAndCloseFormClick}>
                            Save And Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSaveFormClick}>
                            Save
                        </Button>
                    </Box >
                </Box>

                <FormBuilder
                    form={jsonForm}
                    onChange={onSchemaChange}
                    options={{
                        builder: {
                            customBasic: {
                                title: 'Beca',
                                default: true,
                                weight: 0,
                                components: {
                                    archieRoseUploadCustomComp: true,
                                    kendoGridCustomComp: true,
                                    lookupCustomComp: true,
                                    qrScannerCustomComp: true,
                                    kendoUploadCustomComp: true,
                                    cancelButtonCustomComp: true,
                                    dataErrorMessageCustomComp: true,
                                    formSubmissionCustomComp: true,
                                    downloadButtonCustomComp: true
                                }
                            }
                        },
                        userState: userState
                    }}
                />
            </Box>
        </React.Fragment>
    );

    function onSchemaChange(schema) {
        formData = schema;
    }

    function onSaveAndCloseFormClick() {
        put(`admin/form/${groupName}/${formId}Form`, formData)
            .then(() => history.push(`/${tenant}/admin/form/index`))
            .catch(error => throwAsyncError(error));
    }

    function onSaveFormClick() {
        put(`admin/form/${groupName}/${formId}Form`, formData)
            .then(() => history.go(0))
            .catch(error => throwAsyncError(error));
    }
}
