import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import useThrowAsyncError from '../../../../hooks/asyncErrorHook'
import useContext from '../../../../hooks/contextHook';
import { flattenObjInArray } from '../../../../util/helpers'
import DynamicDataGrid from '../../../DynamicDataGrid/DynamicDataGrid';
import Spinner from '../../../Spinner';
import { dataTypes } from '../constants';
import { useGridActions } from './hooks/useGridActions';

const defaultDateTimeFormat = "{0:dd/MM/yyyy}";

export default function Renderer({ component, options, onChange }) {
    const downloadOptions = {
        isDownloadable: component.isDownloadable,
        pathColumn: component.downloadPathColumn,
        nameColumn: component.downloadNameColumn,
        entityIdentifierColumn: component.downloadEntityIdentifierColumn
    }

    const deleteOptions = {
        isDeleteable: component.isDeleteable,
        isHardDelete: component.isHardDelete,
        deletedFlagColumn: component.deletedFlagColumn
    }

    const [columns] = useState(getColumns())
    const [data, setData] = useState();
    const [filters, setFilters] = useState([]);
    const [redirectFormGroup] = useState(getRedirectFormGroup());
    const context = useContext();
    const throwAsyncError = useThrowAsyncError();
    const pageTypes = new Set(["edit", "view", "add", "index"]);
    const gridActions = useGridActions(options.fetch, { ...deleteOptions });

    useEffect(() => {
        options.events.addListener("formio.change", updateDataEventListener);
        return () => {
            options.events.removeListener("formio.change", updateDataEventListener);
        };
    }, []);

    useEffect(() => {
        if (isEmpty(component.refreshEvents)) return;

        for (const event of component.refreshEvents.filter(event => !isEmpty(event.eventName))) {
            options.events.addListener(`formio.${event.eventName}`, fetchTableData);
        }

        return () => {
            for (const event of component.refreshEvents) {
                options.events.removeListener(`formio.${event.eventName}`, fetchTableData);
            }
        };
    }, [component.refreshEvents, options.events])

    const downloadConfigurationIsInvalid = component.isDownloadable && !component.downloadPathColumn;
    const deleteConfigurationIsInvalid = component.isDownloadable && !component.deletedFlagColumn;
    const configurationIsInvalid = !component || !component.tableSelected || downloadConfigurationIsInvalid || deleteConfigurationIsInvalid;
    if (configurationIsInvalid) {
        return <div>Table configuration invalid</div>;
    }

    const isDataFetchRequired = !(options.preview || options.attachMode === "builder" || data);
    if (isDataFetchRequired) {
        //Wait for the form submission data to be ready, so the grid understand which context it renders against
        options.root.submissionReady.then(fetchTableData);
        return <Spinner />;
    }

    return <DynamicDataGrid
        accountIdentifier={options.accountIdentifier}
        tableData={data || []}
        selectable={component.isSelectable}
        sortable={component.isSortable}
        sortBy={component.sortBy}
        sortByDirection={component.sortByDirection}
        pageable={component.isPageable}
        filters={filters}
        filterable={component.isFilterable}
        resizable={component.isResizable}
        reorderable={component.isReorderable}
        groupable={component.isGroupable}
        itemsPerPage={component.pageSize}
        columns={columns}
        deleteOptions={deleteOptions}
        downloadOptions={downloadOptions}
        tableName={redirectFormGroup}
        entityName={component.tableSelected.logicalName}
        entityGroupName={component.tableSelected.name}
        editable={component.isEditable}
        inlineEditable={component.isInlineEditable}
        hasHistory={component.hasHistory}
        creationButton={component.allowCreation ? { label: component.creationLabel } : null}
        primaryKey={component.tableSelected.idColumn}
        viewable={component.isViewable}
        onCreateClick={gridActions?.onCreate}
        onDeleteClick={onDelete}
        onDownloadClick={gridActions?.onDownload}
        onViewClick={(d) => gridActions?.onView(d.id, d.action)}
        onEditClick={(d) => gridActions?.onEdit(d.id, d.action)}
        onSelection={gridActions?.onSelection}
        enableExport={component.isExportable}
        enableExplicitExport={component.enableExplicitExport}
        explicitExportLabel={component.explicitExportLabel}
        explicitExportColumns={component.explicitExportColumns}
        enableColumnToggle={component.enableColumnToggle}
        onChange={onChange}
        addContext={getContextQuery(component.addContext, component.passCurrentContextToAdd)}
        editContext={getContextQuery(component.editContext, component.passCurrentContextToEdit)}
        viewContext={getContextQuery(component.viewContext, component.passCurrentContextToView)}
        gridId={component.id}
        tenant={options.tenant}
        enableBulkAdd={component.enableBulkAdd}
        searchable={component.isSearchable}
    />

    function getColumns() {
        if (!component.columns) return [];

        const dateTimeFormat = component.dateTimeFormat
            ? `{0:${component.dateTimeFormat}}`
            : defaultDateTimeFormat;

        return component.columns.map(col => {
            if (!col.property) return col;

            if (!col.hasOwnProperty("show")) {
                col.show = true;
            }

            switch (col.property.type) {
                case dataTypes.lookup:
                    return updateLookupColKeyAndAttribute(col);
                case dataTypes.dateTime:
                    col.format = dateTimeFormat;
                    col.editorType = "date";
                    col.filterType = "date";
                    break;
                case dataTypes.boolean:
                    col.editorType = "boolean";
                    col.filterType = "boolean"
                    break;
                case dataTypes.integer:
                    col.format = "{0:0}";
                    col.editorType = "numeric";
                    col.filterType = "numeric"
                    break;
                case dataTypes.decimal:
                    col.format = "{0:n}";
                    col.editorType = "numeric";
                    col.filterType = "numeric"
                    break;
                case dataTypes.select:
                    col.type = dataTypes.select;
                    col.editorType = "text";
                    break;
                case dataTypes.multiSelect:
                    col.type = dataTypes.multiSelect;
                    col.editorType = "text";
                    break;
                default:
                    col.editorType = "text";
                    break;
            }

            col.key = col.property ? col.property.value : col.key;
            col.attribute = col.property.value;

            return col;
        });

        function updateLookupColKeyAndAttribute(col) {
            // Set lookup key using '_' because kendo grid doesn't work with '.'
            // Set attribute seperately to sue '.' for dataverse query
            if (col.lookupAttribute && col.lookupAttribute.type === dataTypes.lookup) {
                col.key = `${col.property.navigationProperty}_${col.lookupAttribute.navigationProperty}_${col.furtherLookUp}`;
                col.attribute = `${col.property.navigationProperty}.${col.lookupAttribute.navigationProperty}.${col.furtherLookUp}`;
            } else {
                if (col.lookupAttribute) {
                    col.key = `${col.property.navigationProperty}_${col.lookupAttribute.value}`;
                    col.attribute = `${col.property.navigationProperty}.${col.lookupAttribute.value}`;
                } else {
                    col.key = `${col.property.navigationProperty}_${col.displayAttribute}`;
                    col.attribute = `${col.property.navigationProperty}.${col.displayAttribute}`;
                }
            }
            return col;
        }
    }

    // This is to handle which form to go to when buttons are clicked on the grid. 
    // If the grid is for a different table to the table for the current form, then the buttons will go
    // to forms in the formgroup with the same name as the table of the grid.
    // If the grid is for the same table as the form, then the buttons will go to the forms for the 
    // formgroup of the form. 
    function getRedirectFormGroup() {
        if (!component || !component.tableSelected) return '';

        if (component.tableSelected.name === options.root.form.tableId) {
            return options.groupName;
        }
        return component.tableSelected.name;
    }

    function getContextQuery(formContext, passOriginalContext) {
        const originalContext = window.location.search.substr(1);
        const contextToPass = passOriginalContext ? [originalContext] : [];

        if (!formContext || !Object.keys(formContext).length) return contextToPass;

        const contextKey = `context[${formContext.navigationProperty}.${formContext.idColumn}]`;
        const filterValue = getLastUrlParam(window.location.pathname);
        const filterValueIsAssetId = !pageTypes.has(filterValue);

        if (filterValueIsAssetId) {
            contextToPass.push(`${contextKey}=${filterValue}`);
            return contextToPass;
        }

        const contextContainsFilter = context.filters && context.filters[formContext.value];
        if (contextContainsFilter) {
            contextToPass.push(`${contextKey}=${context.filters[formContext.value]}`);
            return contextToPass;
        }

        return contextToPass;
    }

    function getLastUrlParam(urlPath) {
        const urlParams = urlPath.split('/');
        if (urlParams[urlParams.length - 1] === '') {
            return urlParams[urlParams.length - 2];
        }
        return urlParams[urlParams.length - 1];
    }

    function setupFilter() {
        if (!component.filterDataValueFromApiKey && !component.filterDataValue) return;

        let filterValue = component.filterDataValueFromApiKey
            ? options.root._data[component.filterDataValue]
            : component.filterDataValue;

        // If column is boolean type convert filterValue to bool instead of string
        const filterDataColumnType = columns.find(col => col.property.value === component.filterDataColumn).property.type;
        if (filterDataColumnType === dataTypes.boolean) {
            filterValue = filterValue.toLowerCase() === 'true';
        }

        setFilters([{ field: component.filterDataColumn, operator: "equals", value: filterValue }]);
    }

    async function fetchTableData() {
        const attributeNames = columns
            .map(col => col.attribute)
            .concat(component.tableSelected.idColumn)
            .join(",");

        const shouldFetchData = !data && attributeNames && options.fetch && component.tableSelected && !component.updateFromSubmission;

        if (shouldFetchData) {
            queryTableColumns()
                .then(async data => await queryData(data))
                .catch(error => throwAsyncError(error));
        } else {
            setData(data || []);
        }

        async function queryData(tableColumns) {
            const path = getFilteredPath(tableColumns);
            try {
                let responseData = await options.fetch.get(path)
                let formattedData = await formatData(responseData, tableColumns);
                if (component.addDataToForm) {
                    onChange({ data: flattenObjInArray(formattedData) });
                }
                setData(flattenObjInArray(formattedData));
                setupFilter();
            }
            catch (e) {
                throwAsyncError(e);
            }
        }

        function getFilteredPath(tableColumns) {
            let path = `assets/tables/${component.tableSelected.name}?attributes=${attributeNames}`;

            if (component.filterProperty && component.filterProperty.navigationProperty && component.filterProperty.idColumn) {

                const filterValue = getLastUrlParam(window.location.pathname);
                const filterValueIsAssetId = !pageTypes.has(filterValue);

                if (filterValueIsAssetId) {
                    path += `&filters[${component.filterProperty.navigationProperty}.${component.filterProperty.idColumn}]=${filterValue}`;
                }

            }

            if (context.filters) {
                const tableDirectColumns = tableColumns.map(col => col.value);
                const tableNavigationProperties = tableColumns
                    .filter(col => col.type === dataTypes.lookup)
                    .map(col => col.navigationProperty);

                const allFilterableColumns = tableDirectColumns.concat(tableNavigationProperties);
                const validContextValues = Object.entries(context.filters)
                    .filter(([key]) => allFilterableColumns.includes(key.split('.')[0]));

                const filters = validContextValues.map(([key, value]) => {
                    const navigationProperty = tableNavigationProperties.find(item => item.toLowerCase() === key.toLowerCase());
                    if (navigationProperty) {
                        return `&filters[${navigationProperty}.${key}]=${value}`
                    } else {
                        return `&filters[${key}]=${value}`
                    }
                });

                path += filters.join('');
            }

            return path;
        };

        async function formatData(data, tableColumns) {
            if (isEmpty(data)) return [];

            const dateTimeFields = tableColumns.filter(col => col.type === dataTypes.dateTime).map(col => col.value);
            const selectColumns = columns.filter(col => col.hasOwnProperty("optionSet"))
            const selectTables = selectColumns.map(col => col.optionSet.name)
            const uniqueSelectTables = [...new Set(selectTables)];

            let selectOptions = uniqueSelectTables.map(async col => {
                const optionList = await options.fetch.get(`assets/tables/${col}`);
                return { table: col, options: optionList };
            })
            selectOptions = await Promise.all(selectOptions);
            selectOptions = selectOptions.reduce((acc, cur) => {
                return { [cur.table]: cur.options, ...acc };
            }, {});

            return (data || []).map(dataObj => {
                dateTimeFields.forEach(field => {
                    dataObj[field] = dataObj[field] ? new Date(dataObj[field]) : dataObj[field];
                });

                selectColumns.forEach(field => {

                    if (!dataObj[field.attribute]) {
                        return;
                    }

                    if (field.type === dataTypes.multiSelect) {
                        let selections = dataObj[field.attribute].split(",");
                        dataObj[field.attribute] = selections.map(selection => selectOptions[field.optionSet.name].find(option => option.Value === parseInt(selection))?.Label).join("; ");
                    }

                    if (field.type === dataTypes.select) {
                        dataObj[field.attribute] = selectOptions[field.optionSet.name].find(option => option.Value === dataObj[field.attribute])?.Label;
                    }
                });
                return { ...dataObj };
            });
        }

        async function queryTableColumns() {
            return options.fetch.get(`form/schema/${component.tableSelected.name}/columns`);
        }
    }

    async function onDelete(deleteOptions) {
        try {
            await gridActions.onDelete(deleteOptions);

            if (component.deleteEvent) {
                options.events.emit(`formio.${component.deleteEvent}`, component)
            }
        } catch {
            console.error("There was an error while trying to delete")
        }
    }

    function updateDataEventListener(e) {
        if (!component.updateFromSubmission || options.attachMode === "builder") return;
        setData(flattenObjInArray(e.data[component.key]?.data));
    }
}
