import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card';
import { CardActionArea, CardContent, Typography, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';



function NavCard(props) {

    const history = useHistory()
    const redirectPath = props.linkIsRelative ? `${window.location.pathname}/${props.pageLink}` : `/${props.pageLink}`;
    return (
        <Card >
            <CardActionArea onClick={() => history.push(redirectPath)}>
                <CardContent>

                    <Link color="heading" variant="h4" href={redirectPath}>{props.header}</Link>

                    <Typography component="p">
                        {props.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

NavCard.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    pageLink: PropTypes.string,
    linkIsRelative: PropTypes.bool,  //specifies whether the page link should be appended to the current url (query will be lost)
}

export default NavCard

