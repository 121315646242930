import baseEditForm from "formiojs/components/_classes/component/Component.form";

const settings = (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        key: "placeholder",
                        ignore: false
                    },
                ]
            },
            {
                key: "data",
                components: [
                    {
                        label: "Button Text",
                        key: "buttonText",
                        type: "textfield",
                        input: true,
                        tooltip: "The text displayed on the submission button. eg Submit"
                    },
                    {
                        label: "Submission path",
                        key: "submissionPath",
                        type: "textfield",
                        input: true,
                        tooltip: "Path to the controller action to post data too on submission button click"
                    },
                    {
                        label: "Submission Success Redirect path",
                        key: "successRedirectPath",
                        type: "textfield",
                        input: true,
                        tooltip: "Optional: Path to redirect too upon successful submission response"
                    },
                    {
                        label: "Toggle QrScanner Event",
                        key: "toggleQrScannerEvent",
                        type: "textfield",
                        input: true,
                        tooltip: "Optional: FormIO event that fires upon successful submission response"
                    },
                    {
                        label: "Enable Multiselect",
                        key: "enableMultiselect",
                        type: "checkbox",
                        input: true,
                        tooltip: "Enable mutliple types of scanned values"
                    },
                    {
                        label: "Redirect on Scan",
                        key: "redirectOnScan",
                        type: "checkbox",
                        input: true,
                        tooltip: "Redirect when a scan occurs instead of requiring a submit button to be clicked",
                        customConditional: "show = data.enableMultiselect", //Currently only allow redirection for mutliselect qrScanners
                    },
                    {
                        label: "Redirection location",
                        key: "redirectionLocation",
                        type: "textfield",
                        input: true,
                        tooltip: "Location to redirect to after sucsessful scan. Should be the url path containing the form group and form type (excluding leading and trailing /). e.g. vessel/edit",
                        customConditional: "show = data.redirectOnScan",
                    },
                    {
                        label: "Form values to redirect with",
                        reorder: false,
                        addAnother: "Add Another",
                        addAnotherPosition: "bottom",
                        defaultOpen: false,
                        layoutFixed: false,
                        enableRowGroups: false,
                        initEmpty: false,
                        tableView: true,
                        defaultValue: [
                            {
                                typeId: "",
                                regexValidation: ""
                            }
                        ],
                        validate: {
                            minLength: "1"
                        },
                        key: "formRedirectionFields",
                        customConditional: "show = data.redirectOnScan",
                        tooltip: "Fields from the current form to include in the redirection context",
                        type: "datagrid",
                        input: true,
                        components: [
                            {
                                label: "Redirection context type",
                                tableView: true,
                                key: "redirectionContextType",
                                type: "textfield",
                                input: true,
                                tooltip: "The context type to apply for redirection. Current options include filter/set and context (both filter and set)."
                            },
                            {
                                label: "Field Form ApiKey",
                                tableView: true,
                                key: "redirectionContextData",
                                type: "textfield",
                                input: true,
                                tooltip: "The ApiKey for the data to be added to the redirection context"
                            },
                            {
                                label: "Redirection parameter name",
                                tableView: true,
                                key: "redirectionParameterName",
                                type: "textfield",
                                input: true,
                                tooltip: "The parameter name to use within the context for the redirection"
                            },
                        ]
                    },
                    {
                        label: "Add Code Types",
                        reorder: false,
                        addAnother: "Add Another Scan Type",
                        addAnotherPosition: "bottom",
                        defaultOpen: false,
                        layoutFixed: false,
                        enableRowGroups: false,
                        initEmpty: false,
                        tableView: true,
                        defaultValue: [
                            {
                                typeId: "",
                                regexValidation: ""
                            }
                        ],
                        validate: {
                            minLength: "1"
                        },
                        key: "codeTypes",
                        customConditional: "show = data.enableMultiselect",
                        type: "datagrid",
                        input: true,
                        components: [
                            {
                                key: "namingGroup",
                                type: "fieldset",
                                label: "Naming",
                                input: false,
                                tableView: false,
                                components: [

                                    {
                                        label: "Type",
                                        spellcheck: false,
                                        tableView: true,
                                        key: "typeId",
                                        type: "textfield",
                                        input: true,
                                        tooltip: "Name of the Type for multiselection, displayed on the combobox"
                                    },
                                    {
                                        label: "Sub ApiKey",
                                        spellcheck: false,
                                        tableView: true,
                                        key: "apiKey",
                                        type: "textfield",
                                        input: true,
                                        tooltip: "This is where the entered qr codes will be stored in the data object. As data.<QR Scanner apikey>.<sub api Key>"
                                    },
                                ]
                            },
                            {
                                key: "logicGroup",
                                type: "fieldset",
                                label: "Logic",
                                input: false,
                                tableView: false,
                                components: [

                                    {
                                        label: "Regex Validation",
                                        spellcheck: false,
                                        tableView: true,
                                        key: "regexValidation",
                                        type: "textfield",
                                        input: true,
                                        tooltip: "Regex to validate that a scanned Qr code matches the type"
                                    },
                                    {
                                        label: "Maximum allowed",
                                        tableView: true,
                                        key: "maximumAllowed",
                                        type: "number",
                                        input: true,
                                        tooltip: "Maximum number of values allowed for the given type"
                                    },
                                ]
                            },
                            {
                                key: "redirectionGroup",
                                type: "fieldset",
                                label: "Redirection Options",
                                input: false,
                                tableView: false,
                                components: [
                                    {
                                        label: "Required for Redirect",
                                        tableView: true,
                                        key: "requiredForRedirect",
                                        type: "checkbox",
                                        input: true,
                                        customConditional: "show = data.redirectOnScan",
                                        tooltip: "Is this field required to initiate a redirection"
                                    },
                                    {
                                        label: "Redirection context type",
                                        tableView: true,
                                        key: "redirectionContextType",
                                        type: "textfield",
                                        input: true,
                                        customConditional: "show = row.requiredForRedirect",
                                        tooltip: "The context type to apply for redirection. Current options include filter/set and context (both filter and set)."
                                    },
                                    {
                                        label: "Redirection parameter name",
                                        tableView: true,
                                        key: "redirectionParameterName",
                                        type: "textfield",
                                        input: true,
                                        customConditional: "show = row.requiredForRedirect",
                                        tooltip: "The parameter name to use within the context for the redirection"
                                    },
                                    {
                                        label: "Redirection requires quotation",
                                        tableView: true,
                                        key: "redirectionParameterRequiresQuotation",
                                        type: "checkbox",
                                        input: true,
                                        customConditional: "show = row.requiredForRedirect",
                                        tooltip: "Does the redirection parameter require being surrounded by quotes? Hint most Id fields will require quotation. String fields should not require it."
                                    },
                                ]
                            },
                            {
                                label: "Prepopulate",
                                tableView: true,
                                key: "prepopulate",
                                type: "fieldset",
                                input: false,
                                components: [
                                    {
                                        label: "Prepopulate",
                                        tableView: true,
                                        key: "isPrepopulated",
                                        type: "checkbox",
                                        input: true,
                                        tooltip: "Enable prepopulation of the scanned Qr code comboboxes"
                                    },
                                    {
                                        label: "Prepopulate Table",
                                        tableView: true,
                                        key: "prepopulateTable",
                                        customConditional: "show = row.isPrepopulated",
                                        type: "textfield",
                                        input: true,
                                        tooltip: "FormIO api key for a table to search for prepopulation data within"
                                    },
                                    {
                                        label: "Prepopulate Key",
                                        tableView: true,
                                        key: "prepopulateKey",
                                        customConditional: "show = row.isPrepopulated",
                                        type: "textfield",
                                        input: true,
                                        tooltip: "FormIO api key for a field to search for prepopulation data. If a table key is also provided prepopulation will search for the given key within the given table."
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: []
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};

export default settings;
