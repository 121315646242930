import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux'

import { qrCodeReducer } from './QrCodeReducer';
import { sampleReducer } from './SampleReducer'
import { userReducer } from './UserReducer'
import { formReducer } from './FormReducer'

// add all the reducers to this function (eg replace 'sampleReducer' with 'sampleReducer, menuReducer, AssetReducer')
const createRootReducer = (history) => combineReducers({
    sample: sampleReducer,
    qrCode: qrCodeReducer,
    user: userReducer,
    form: formReducer,
    router: connectRouter(history)
})

export default createRootReducer