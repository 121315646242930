// helper function to flatten an object recursively

import { ContextParams } from "../components/DynamicDataGridV2/hooks/getActionPath";
import { localStorageUtility } from "./storage";

// Ref: https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
function flattenObj(obj: any, prefix?: any): any {
    return Object.entries(obj).reduce((o, [key, value]) => {
        key = prefix ? `${prefix}_${key}` : key;

        if (typeof value === 'object' && value != null && Object.keys(value).length) {
            return { ...o, ...flattenObj(value, key) };
        }

        return { ...o, [key]: value };
    }, {});
}

export function unique(array: any[]) {
    return array.filter((value, index, self) => self.indexOf(value) === index);
}

/**
 * Get a property from an object using a dynamic path
 * Example:
 *	- input		path: adminGroup.1.user.company.name
 *				data = {
 *					adminGroup: [
 *						{ user: {name: 'Bill', company: { name: 'Microsoft' }}},
 *						{ user: {name: 'Greg', company: { name: 'Beca' }}},
 *						{ user: {name: 'Elon', company: { name: 'Tesla' }}},
 *					]
 *				}
 *	- output: 'Beca'
 */
export function getNestedProperty(data: any, path: string) {
    return path.split('.').reduce((acc, next) => acc ? acc[next] : undefined, data)
}

// helper function to flatten objects in an array
export function flattenObjInArray(array: any[]) {
    if (Array.isArray(array)) {
        return array.map(item => flattenObj(item));
    }
}

export function getFilter(uriQuery: { context?: any; }) {
    var filters = '?filters';
    const context = uriQuery.context;
    if (context) {
        Object.entries(context).forEach(([key, value]) => {
            filters += `[${key}]=${value}`;
        });
    }
    return filters;
}

export const localStorageUtils = {
    setWithExpiry: (key: string, value: any, ttlInMinutes: number) => {
        const now = new Date();

        const item = {
            value,
            expiry: now.getTime() + (ttlInMinutes * 60 * 1000)
        }
        try {
            localStorageUtility.setItem(key, JSON.stringify(item));
        } catch {
            return;
        }
    },

    getWithExpiry: (key: string) => {
        const itemStr = localStorageUtility.getItem(key);

        if (!itemStr) {
            return null;
        }

        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorageUtility.removeItem(key);
            return null;
        }

        return item.value;
    }
}

type Builder = {
    addQuery: (key: string, value: string) => void,
    url: string,
    query: any[],
    build: () => string,
}

export function urlBuilder(baseUrl: string): Builder {
    let builder: Builder = {
        addQuery: (key: string, value: string) => {
            builder.query.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            );
        },
        url: baseUrl,
        query: [],
        build: () => `${builder.url}?${builder.query.join("&")}`,
    };
    return builder;
}

export const getPassedContextParamString = (passedContext: ContextParams) => {
    const queryString = passedContext.join('&');
    return queryString ? `?${queryString}` : '';
}

// Make objects with non-enumerable properties (eg. Errors) serialisable
// Ref: https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
export function getSerialisable(object: any) {
    return Object.getOwnPropertyNames(Object.getPrototypeOf(object)).reduce((iter: any, key) => {
        iter[key] = object[key];
        return iter;
    }, {});
}