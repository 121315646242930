import { useState, useEffect } from 'react';
import { urlBuilder } from "../../util/helpers";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { Add, Close } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Grid, Box, Button, Card, CardHeader, IconButton, TextField, CircularProgress } from '@material-ui/core';
import QrReader from './QrReader'
import { get, isEqual, set } from 'lodash';

const useStyles = makeStyles(theme => ({
    hideInput: {
        padding: 0,
        height: 0
    },
    cardHeader: {
        [theme.breakpoints.up("sm")]: {
            paddingBottom: 0
        }
    },
    qrScanner6: {
        [`${theme.breakpoints.up("md")} and (orientation: landscape)`]: {
            width: "60%",
        },
        [`${theme.breakpoints.up("md")} and (orientation: portrait)`]: {
            width: "90%"
        },
        [`${theme.breakpoints.down("sm")} and (orientation: portrait)`]: {
            width: "80%"
        },
        [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            width: "45%"
        },
        [`${theme.breakpoints.down("xs")} and (orientation: portrait)`]: {
            width: "100%"
        }
    },
    qrScanner12: {
        [theme.breakpoints.down("lg")]: {
            width: "25%",
        },
        [`${theme.breakpoints.down("md")} and (orientation: portrait)`]: {
            width: "100%"
        },
        [`${theme.breakpoints.down("md")} and (orientation: landscape)`]: {
            width: "30%"
        },
        [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            width: "40%",
        },
    },
    multiSelects: {
        [`${theme.breakpoints.up("md")} and (orientation: landscape)`]: {
            paddingRight: theme.spacing(5)
        },
        paddingRight: 0,
    },
}));

function QrScanner(props) {

    const [qrCode, setQrCode] = useState("");
    const [multiselectValues, setMultiselectValues] = useState(props.initialMultiselectValues);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const classes = useStyles();
    const hasData = props.enableMultiselect
        ? props.codeTypes.every(type => multiselectValues[type.typeId]?.length > 0)
        : qrCode != "";
    const [loading, setLoading] = useState(false);

    const qrScannerClass = props.enableMultiselect ? classes.qrScanner6 : classes.qrScanner12;

    // Pass data out to FormIO
    let onChange = props.onChange;

    useEffect(() => {
        if (!props.enableMultiselect) {
            onChange(qrCode)
        }
    }, [qrCode, onChange, props.enableMultiselect])

    useEffect(() => {
        if (props.enableMultiselect) {
            const updatedApiKeys = props.codeTypes.reduce((acc, type) => {

                const key = type.apiKey || type.typeId;
                acc[key] = multiselectValues[type.typeId];

                return acc;
            }, {})

            onChange(updatedApiKeys)
        }
    }, [onChange, multiselectValues, props.enableMultiselect, props.codeTypes]);

    useEffect(() => {
        props.events.addListener("formio.change", handleFormIOChange);
        return () => {
            props.events.removeListener("formio.change", handleFormIOChange);
        };
    }, []);

    function handleFormIOChange({ changed, data }) {
        if (!props.enableMultiselect) {
            setFormData(data);
            return;
        }
        if (changed?.component?.key == props.componentKey)
            return;
        const initialMultiSelectValues = { ...multiselectValues };
        const prepopulateTypes = props.codeTypes?.filter(type => type.isPrepopulated);
        prepopulateTypes?.forEach(type => {
            if (!type.prepopulateKey)
                return console.warn("Prepopulate key missing");
            const rootData = type.prepopulateTable
                ? data[type.prepopulateTable]
                : [data];
            if (!rootData || !Array.isArray(rootData))
                return console.warn("Prepopulate data missing");
            const currentValues = initialMultiSelectValues[type.typeId] || [];
            const prepopulateData = rootData.map(data => get(data, type.prepopulateKey))
                .filter(data => data && !currentValues.includes(data));
            initialMultiSelectValues[type.typeId] = currentValues.concat(prepopulateData);
        });
        if (isEqual(multiselectValues, initialMultiSelectValues)) {
            console.log("No values updated because data is the same");
            return;
        }
        setMultiselectValues(initialMultiSelectValues);
    }

    function handleScan(data) {
        if (data !== null && data !== qrCode) {
            setQrCode(data);
            setError(null);
        }
    }

    function handleError(error) {
        setQrCode("");
        setError(error.message);
    }

    function handleTextChange(event) {
        setQrCode(event.target.value);
        setError(null);
    }

    function handleQrSubmit() {
        let singularQrScanValid = !props.enableMultiselect && qrCode?.lenth !== 0;
        let multiQrScanValid = props.enableMultiselect && Object.keys(multiselectValues).every((val) => val.length > 0);

        if (!singularQrScanValid && !multiQrScanValid) {
            setError("Please scan a QR code");
            return;
        }

        if (props.post !== undefined) {
            let submissionData = props.enableMultiselect ? { [props.componentKey]: { ...multiselectValues } } : { ...formData }
            setLoading(true);
            props.post(props.submissionPath, submissionData)
                .then((response) => {
                    setLoading(false);
                    if (props.toggleQrScannerEvent) {
                        props.events.emit(`formio.${props.toggleQrScannerEvent}`);
                    }

                    if (response && props.successRedirectPath) {
                        handleSuccessfulPostRedirect(response);
                    } else {
                        props.refreshSubmission();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    handleError(error);
                });
        }
    }

    function handleSuccessfulPostRedirect(response) {
        let path = props.successRedirectPath;
        //If the path contains a {variable} substitute that variable from the response 
        //into the redirect path. Currently only works for one variable
        let responseVariableName = path.substring(path.lastIndexOf("{") + 1, path.lastIndexOf("}"));
        if (responseVariableName) {
            var responseData = response[responseVariableName];
            let splitPath = path.split("{");
            window.location.href = `${splitPath[0]}${responseData}`;
        } else {
            window.location.href = path;
        }
    }

    function closeQrScanner() {
        if (props.toggleQrScannerEvent) {
            props.events.emit(`formio.${props.toggleQrScannerEvent}`);
        }
    }

    function multiselectAddCode() {
        let updatedData = { ...multiselectValues };

        props.codeTypes.forEach(type => {
            let regexExpression = new RegExp(type.regexValidation)
            if (type.regexValidation && !regexExpression.test(qrCode)) return;

            let codeNotAlreadySelected = !updatedData[type.typeId].includes(qrCode);
            let belowMaximumForType = type.maximumAllowed === undefined || updatedData[type.typeId].length < type.maximumAllowed;

            if (codeNotAlreadySelected && belowMaximumForType) {
                updatedData[type.typeId].push(qrCode);
                qrScannerRedirect();
            }
        });

        setQrCode("");
        setMultiselectValues(updatedData)
        props.onChange(updatedData);
    }

    function qrScannerRedirect() {
        if (!props.redirectOnScan) return;
        let typesRequiredForRedirection = props.codeTypes.filter(type => type.requiredForRedirect);
        let allRedirectionTypesScanned = typesRequiredForRedirection.every(type => multiselectValues[type.typeId].length === type.maximumAllowed);
        if (!allRedirectionTypesScanned) return;

        let pathBuilder = urlBuilder(`/${props.tenant}/form/${props.redirectionLocation}/`);

        typesRequiredForRedirection.forEach(type => {
            let quotation = type.redirectionParameterRequiresQuotation ? "'" : "";
            pathBuilder.addQuery(`${type.redirectionContextType}[${type.redirectionParameterName}]`, `${quotation}${multiselectValues[type.typeId]}${quotation}`);
        });

        props.formRedirectionFields && props.fetchFormRedirectionFields().forEach(field => {
            pathBuilder.addQuery(`${field.redirectionContextType}[${field.redirectionParameterName}]`, `'${field.data}'`);
        });

        props.navigationTo({ pathBuilder }).go();
    }

    function multiselectOnChange(event, value, reason) {
        if (reason === "remove-option") {
            let updatedData = { ...multiselectValues };
            // This could be handled better by extracting the 
            // autocomplete into its own comboBox component
            let changedId = event.target.closest("div .MuiBox-root").id;
            updatedData[changedId] = value;
            setMultiselectValues(updatedData);
        }
    }

    return (
        <Box margin="auto" height={1} width={1}>
            <Card variant="outlined">
                <CardHeader className={classes.cardHeader}
                    action={
                        <IconButton variant="contained" onClick={closeQrScanner} aria-label="close">
                            <Close />
                        </IconButton>
                    }
                    title={props.label}>
                </CardHeader>
                <Box mx="auto">
                    <Box mx={2} my={1}>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">
                            <Grid item lg={props.enableMultiselect ? 5 : 12} md={props.enableMultiselect ? 5 : 12} xs={12}>
                                <Box className={qrScannerClass} margin="auto">
                                    {error &&
                                        <Box display="flex" marginY={1} justifyContent="center">
                                            <Alert severity="error">Something went wrong: {error}</Alert>
                                        </Box>
                                    }
                                    <QrReader
                                        delay={100}
                                        onError={handleError}
                                        onScan={handleScan}
                                        retryAttemptTotal={3}
                                        reactPlugin={props.reactPlugin}
                                    />
                                    <Box mt={1}>
                                        <TextField
                                            placeholder={props.manualEntryPlaceholder}
                                            value={qrCode}
                                            onChange={handleTextChange}
                                            required={true}
                                            fullWidth={true}
                                        />
                                    </Box>
                                    {!props.enableMultiselect &&
                                        (
                                            <Box mt={2}>
                                                <ActionButtons />
                                            </Box>
                                        )}
                                </Box>
                            </Grid>
                            <br />
                            {props.enableMultiselect &&
                                <Grid item lg={7} md={7} xs={12}>
                                    <Box className={classes.multiSelects}>
                                        {multiselectValues && <MultiSelects />}
                                        <ActionButtons />
                                    </Box>
                                </Grid>}
                        </Grid>
                    </Box>
                </Box>
            </Card>
        </Box>
    );

    function ActionButtons() {
        return (
            <Box display="flex" width={1} justifyContent="space-between">
                <Button variant="contained" onClick={closeQrScanner}>Cancel</Button>
                {props.buttonText && props.submissionPath &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleQrSubmit}
                        disabled={!hasData || loading}
                    >
                        {loading ? <CircularProgress size="0.875rem" /> : props.buttonText}
                    </Button>

                }
            </Box>
        )
    }

    function MultiSelects() {
        return (
            <Box>
                <Box display={{ xs: 'flex', md: 'block' }} justifyContent="center">
                    <Button variant="contained" color="primary" onClick={multiselectAddCode} aria-label="add">
                        <Add fontSize="small" />
                        Add
                    </Button>
                </Box>
                {props.codeTypes.filter(codeType => codeType.typeId !== "").map(createComboboxForType)}
            </Box>
        )
    }

    function createComboboxForType({ typeId }, index) {
        let inputClass = multiselectValues[typeId].length > 0 ? classes.hideInput : null;
        return (
            <Box key={index} id={typeId} marginY={2} width={1}>
                <Autocomplete
                    multiple
                    disableClearable
                    forcePopupIcon={false}
                    open={false}
                    selectOnFocus={false}
                    options={[]}
                    value={multiselectValues[typeId]}
                    onChange={multiselectOnChange}
                    id={typeId}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{ readOnly: true, disabled: true, className: inputClass }}
                            multiline={false}
                            variant="outlined"
                            label={typeId}
                        />
                    )}
                />
            </Box>
        )
    }
}

QrScanner.propTypes = {
    onChange: PropTypes.func,
    post: PropTypes.func,
    events: PropTypes.object,
    refreshSubmission: PropTypes.func,
    componentKey: PropTypes.string,
    manualEntryPlaceholder: PropTypes.string,
    buttonText: PropTypes.string,
    submissionPath: PropTypes.string,
    successRedirectPath: PropTypes.string,
    toggleQrScannerEvent: PropTypes.string,
    label: PropTypes.string,
    enableMultiselect: PropTypes.bool,
    codeTypes: PropTypes.array,
    initialMultiselectValues: PropTypes.object,
    redirectionLocation: PropTypes.string,
    tenant: PropTypes.string,
    formRedirectionFields: PropTypes.object,
    fetchFormRedirectionFields: PropTypes.func,
    navigationTo: PropTypes.func,
    disableQrScanner: PropTypes.bool,
}

export default QrScanner;
