import { GridCellProps } from '@progress/kendo-react-grid';
import getActionPath, { ApiInfo, ContextParams} from '../hooks/getActionPath';

export interface ViewClickProps {
    id: string
    action: any
}

interface ViewActionProps {
    apiInfo: ApiInfo
    context: ContextParams
    data: GridCellProps
    onClick: (props: ViewClickProps) => void
}

export default function ViewAction({apiInfo, context, data, onClick}: ViewActionProps) {
    const path = getActionPath(data, "view", context, apiInfo)
    return (
        <a href={path} onClick={() => onViewClick(data)} >
            <span className="k-icon k-i-eye" title="View row" />
        </a>
    )

    function onViewClick(data:GridCellProps) {
        if (!onClick) {
            console.warn(`onViewClick triggered for table ${apiInfo.tableName} without an onViewClick action defined`);
            return;
        }

        const id = data.dataItem[apiInfo.primaryKey];
        const action = `grid/${apiInfo.tableName}/view`;
        onClick({ id, action });
    }
}