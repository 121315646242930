import { useMemo } from "react";
import { dataTypes } from "../components/Form/CustomComponents/constants";


const defaultDateTimeFormat = "{0:dd/MM/yyyy}";

export function useGridColumns(columns: any[], dateTimeFormatString: string) {

    const gridColumns = useMemo(() =>
        getGridColumns(columns, dateTimeFormatString),
        [columns, dateTimeFormatString])

    return { gridColumns };
}

function getGridColumns(columns: any[], dateTimeFormatString: string) {
    if (!columns) return [];

    const dateTimeFormat = dateTimeFormatString
        ? `{0:${dateTimeFormatString}}`
        : defaultDateTimeFormat;

    return columns.map(col => {
        if (!col.property) return col;

        const format = getColumnFormat(col, dateTimeFormat);
        return {
            ...col,
            key: col.property ? col.property.value : col.key,
            attribute: col.property.value,
            show: col.show ?? true,
            ...format
        }
    });
}

function getColumnFormat(col: any, dateTimeFormat: string) {

    switch (col.property.type) {
        case dataTypes.lookup:
            return updateLookupColKeyAndAttribute(col);
        case dataTypes.dateTime:
            return {
                format: dateTimeFormat,
                editorType: "date",
                filterType: "date"
            }
        case dataTypes.boolean:
            return {
                editorType: "boolean",
                filterType: "boolean"
            }
        case dataTypes.integer:
            return {
                format: "{0:0}",
                editorType: "numeric",
                filterType: "numeric"
            }
        case dataTypes.decimal:
            return {
                format: "{0:n}",
                editorType: "numeric",
                filterType: "numeric"
            }
        case dataTypes.select:
            return {
                type: dataTypes.select,
                editorType: "text"
            }
        case dataTypes.multiSelect:
            return {
                type: dataTypes.multiSelect,
                editorType: "text"
            }
        default:
            return {
                editorType: "text"
            }
    }
}

function updateLookupColKeyAndAttribute(col: any) {
    // Set lookup key using '_' because kendo grid doesn't work with '.'
    // Set attribute seperately to sue '.' for dataverse query
    if (col.lookupAttribute && col.lookupAttribute.type === dataTypes.lookup) {
        return {
            key: `${col.property.navigationProperty}_${col.lookupAttribute.navigationProperty}_${col.furtherLookUp}`,
            attribute: `${col.property.navigationProperty}.${col.lookupAttribute.navigationProperty}.${col.furtherLookUp}`
        }
    }
    if (col.lookupAttribute) {
        return {
            key: `${col.property.navigationProperty}_${col.lookupAttribute.value}`,
            attribute: `${col.property.navigationProperty}.${col.lookupAttribute.value}`
        }
    }
    return {
        key: `${col.property.navigationProperty}_${col.displayAttribute}`,
        attribute: `${col.property.navigationProperty}.${col.displayAttribute}`
    }
}