import { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./Lookup.settingsForm";
import Renderer from "./Renderer";
import { Renderer as RendererV2 } from "./RendererV2";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../../../theme';
import { dataTypes } from '../constants';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const LookupCustomComp = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
        if (process.env.REACT_APP_FORMIO_DEBUG_LOGS)
            console.debug("LookupCustomComp:constructor", { label: props.component.label, key: props.component.key });
    }

    setValue = () => {
        this.setState(
            prevState => ({ value: !prevState.value }),
            () => this.props.onChange(null, this.state.value)
        );
    };

    render() {
        if (!this.props) {
            return null;
        }
        const { options, component } = this.props;
        const featureFlag = options?.featureFlags?.['lookup_RendererV2'];
        const supportedType = component.dropdownType !== dataTypes.select && component.dropdownType !== dataTypes.select;
        return (
            <ThemeProvider theme={theme}>
                {featureFlag && supportedType ? <RendererV2 {...this.props} /> : <Renderer {...this.props} />}
            </ThemeProvider>
        )
    }
};

export default class Lookup extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: "Lookup",
            icon: "list",
            group: "Beca",
            documentation: "",
            schema: Lookup.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: "lookupCustomComp",
            label: "Lookup"
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        if (process.env.REACT_APP_FORMIO_DEBUG_LOGS)
            console.debug("Lookup:attachReact", { label: this.component.label });
        return ReactDOM.render(
            <LookupCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                options={this.options}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            if (process.env.REACT_APP_FORMIO_DEBUG_LOGS)
                console.debug("Lookup:detachReact", { label: this.component.label });
            ReactDOM.unmountComponentAtNode(element);
        }
    }
}