
//Code from https://stackoverflow.com/questions/12180291/storing-a-list-of-key-value-pairs-in-a-url-parameter-javascript
export default function useUriQuery() {
    var ret = {};
    var queryString = window.location.search.substring(1);
    queryString.split("&").forEach((x) => {
        var data = x.split('='),
            name = decodeURIComponent(data.shift()),
            val = decodeURIComponent(data.join("=")).replace('+', ' '),
            nameVal = name.match(/(.*)\[(.*)\]/);

        if (nameVal === null) {
            ret[name] = val;
        }
        else {
            name = nameVal[1];
            nameVal = nameVal[2];
            if (!ret[name]) {
                ret[name] = nameVal ? {} : [];
            }
            if (Object.prototype.toString.call(ret[name]) === '[object Object]') {
                ret[name][nameVal] = val;
            }
            else if (Array.isArray(ret[name])) {
                ret[name].push(val);
            }
        }
    });
    return ret;
};