import React from 'react';
import QrScanner from '../../../QrScanner/QrScanner';

export default function Renderer(props) {
    var { component, options, onChange } = props;
    let initialMultiSelectValues = {};
    component.codeTypes?.forEach(type => initialMultiSelectValues[type.typeId] = []);

    function fetchFormRedirectionFields() {
        component.formRedirectionFields.forEach(field => {
            const redirectionContextData = field.redirectionContextData.split('.');
            field.data = options.root._data;
            redirectionContextData.forEach(redirectionContext => {
                field.data = field.data[redirectionContext];
            });
        });
        return component.formRedirectionFields;
    }

    // If the QrScanner is being displayed in the form editor return an empty function as it will never be called
    // If options.navigation is present the QrScanner is being rendered on an Add/Edit/Index page and the 
    // navigationTo hook will be available.
    let editorNavigationTo = () => { };
    let navigationTo = options.navigation ? options.navigation.navigationTo : editorNavigationTo;
    const isPreview = options.preview || options.attachMode === "builder";

    return (
        <QrScanner
            onChange={onChange}
            post={options?.fetch?.post}
            events={options?.events}
            refreshSubmission={options.refreshSubmission}
            componentKey={component.key}
            manualEntryPlaceholder={component.placeholder}
            buttonText={component.buttonText}
            submissionPath={component.submissionPath}
            successRedirectPath={component.successRedirectPath}
            toggleQrScannerEvent={component.toggleQrScannerEvent}
            label={component.label}
            enableMultiselect={component.enableMultiselect}
            codeTypes={component.codeTypes}
            initialMultiselectValues={initialMultiSelectValues}
            tenant={options.tenant}
            redirectionLocation={component.redirectionLocation}
            formRedirectionFields={component.formRedirectionFields}
            fetchFormRedirectionFields={fetchFormRedirectionFields}
            navigationTo={navigationTo}
            redirectOnScan={component.redirectOnScan}
            disableQrScanner={isPreview || component.disabled}
            reactPlugin={options?.reactPlugin}
        />
    );
}
