import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./QrScanner.settingsForm";
import Renderer from "./Renderer";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../../../theme';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const QrscannerCustomComp = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    setValue = () => {
        this.setState(
            prevState => ({ value: !prevState.value }),
            () => {
                this.props.onChange(null, this.state.value);
            }

        );
    };

    render() {
        if (!this.props) {
            return null;
        }
        return (
            <ThemeProvider theme={theme}>
                <Renderer {...this.props} />
            </ThemeProvider>
        )
    }
};

export default class QrScanner extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */

    static get builderInfo() {
        return {
            title: "QR Scanner",
            icon: "camera",
            group: "Data",
            documentation: "",
            schema: QrScanner.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: "qrScannerCustomComp",
            label: "QR Scanner",
            placeholder: "Manual entry placeholder",
            buttonText: "",
            submissionLink: "",
            successRedirectPath: "",
            toggleQrScannerEvent: "",
            enableMultiselect: false,
            codeTypes: [],
            initialMultiselectValues: {}
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        if (process.env.REACT_APP_FORMIO_DEBUG_LOGS)
            console.debug("QRScanner:attachReact", { ...this.component })
        return ReactDOM.render(
            <QrscannerCustomComp
                {...this}
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                options={this.options}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            if (process.env.REACT_APP_FORMIO_DEBUG_LOGS)
                console.debug("QRScanner:detachReact", { ...this.component })
            ReactDOM.unmountComponentAtNode(element);
        }
    }
}
