import Reader from 'react-qr-reader'
import { useTrackEvent  } from "@microsoft/applicationinsights-react-js";
import { reactPlugin as appInsightsPlugin } from '../../AppInsights'
import { useState } from 'react';
import { getSerialisable } from "../../util/helpers";

interface QrReaderProps {
    delay: number
    onError: (error: any) => void
    onScan: (data: string | null) => void
    reactPlugin: any
    retryAttemptTotal: number
}

const defaults = {
    delay: 100,
    onError: () => { throw new Error('onError for QrReader is not defined') },
    onScan: () => { throw new Error('onScan for QrReader is not defined') },
    reactPlugin: appInsightsPlugin,
    retryAttemptTotal: 3,
}

export default function QrReader({ 
    delay = defaults.delay, 
    onError = defaults.onScan,
    onScan = defaults.onScan,
    reactPlugin = defaults.reactPlugin, 
    retryAttemptTotal = defaults.retryAttemptTotal,  
}: QrReaderProps) {

    const [retries, setRetries] = useState(0);
    const appInsights = reactPlugin || appInsightsPlugin;
    const trackQrCodeFailure = useTrackEvent(appInsights, "QR Code Failure", {}, true);

    function handleQrReaderError(error: any) {
        if (retries <= retryAttemptTotal) {
            return setTimeout(() => setRetries((data) => data + 1), 1000);
        } else {
            onError(error);
            trackQrCodeFailure(getSerialisable(error));
        }
    }

    return (
        <Reader
            delay={delay}
            onError={handleQrReaderError}
            onScan={onScan}
            key={`${retries}`}
        />
    )
} 