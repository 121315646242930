import { createRef } from 'react';
import { Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import ColumnSelector from "./ColumnSelector"
import CreateButton from './CreateButton';
import DataExport from "./DataExport"
import ExplicitExport from "./ExplicitExport"
import SearchBar from './SearchBar';

interface GridToolbarProps {
    enableColumnToggle: boolean
    enableExplicitExport: boolean
    enableExport: boolean
    enableGlobalSearch: boolean
    explicitExportColumns: string
    explicitExportLabel: string

    handleSearch: (query:string) => void
        
    exportIds: string[]
    selectable: boolean

    creationButtonSettings: any
    tenant: string
    addContext: any
    tableName: string
    onCreateClick: (path: string) => void

    columns: any[]
    //tableData: any[]
    primaryKey: string

    updateColumns: (columns: any[]) => void
}

export function GridToolbar(props: GridToolbarProps) {

    const kendoExport = createRef<ExcelExport>();

    return (
        <>
            {
                //<ExcelExport data={props.tableData} ref={kendoExport} />
            }
            <Toolbar className="border-bottom-0">
                {/*props.enableExplicitExport && props.selectable &&
                    <ToolbarItem>
                        <ExplicitExport 
                            columns={props.columns} 
                            explicitExportColumns={props.explicitExportColumns}
                            explicitExportLabel={props.explicitExportLabel}
                            kendoExport={kendoExport} 
                            primaryKey={props.primaryKey} 
                            selectedIds={props.exportIds} 
                            tableData={props.tableData} 
                            />
                    </ToolbarItem>
        */}
                {/*props.enableExport && props.selectable &&
                    <ToolbarItem>
                        <DataExport 
                            columns={props.columns} 
                            kendoExport={kendoExport} 
                            primaryKey={props.primaryKey} 
                            selectedIds={props.exportIds} 
                            tableData={props.tableData}/>
                    </ToolbarItem>
        */}
                {props.enableColumnToggle &&
                    <ToolbarItem>
                        <ColumnSelector columns={props.columns} updateColumns={props.updateColumns} />
                    </ToolbarItem>
                }
                {props.creationButtonSettings &&
                    <CreateButton
                        buttonSettings={props.creationButtonSettings}
                        tenant={props.tenant}
                        addContext={props.addContext}
                        tableName={props.tableName}
                        onCreateClick={props.onCreateClick}
                    />
                }
                {props.enableGlobalSearch &&
                    <SearchBar
                        handleSearch={props.handleSearch}
                    />
                }

            </Toolbar>
        </>
    )
}