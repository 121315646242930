import { UPDATE_QRCODE } from "../ActionTypes";

// STATE
const defaultState = {
    qrCode: '',
    error: false
}

// REDUCER
export function qrCodeReducer(state = defaultState, action) {

    switch (action.type) {
        case UPDATE_QRCODE:
            {
                return {
                    ...state,
                    qrCode: action.payload.qrCode,
                    error: action.payload.error
                }
            }
        default:
            {
                return state;
            }
    }
}

