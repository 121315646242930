import { useMemo, useState } from "react";
import { GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { SortBy, SortByDirection } from "../../../types";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { isEmpty } from "lodash";

type Filter = CompositeFilterDescriptor | FilterDescriptor

function defaultSortState(sortBy: SortBy, sortByDirection?: SortByDirection): SortDescriptor[] {
    return [
        { field: sortBy?.value || "", dir: sortByDirection || "asc" }
    ];
}

function defaulFilterState(filters: Filter[]): CompositeFilterDescriptor | undefined {
    if (isEmpty(filters))
        return;
    return {
        logic: "and",
        filters: [
            ...filters
            /*{
                field: InternalProperties.SHOULD_SHOW,
                operator: 'eq',
                value: true
            }*/
        ]
    }
}

function defaultPagingState(itemsPerPage: number): { skip: number, take: number } {
    return {
        skip: 0,
        take: itemsPerPage
    }
}

interface GridState {
    filterState: CompositeFilterDescriptor | undefined
    sortState: SortDescriptor[]
    pagingState: any
}

interface DigitalTwinFilter {
    [key: string]: string[]
}

export function useGridState(itemsPerPage: number, filters: any[], sortBy: SortBy, sortByDirection: SortByDirection, receivedFilter: DigitalTwinFilter, primaryKey: string) {

    const [gridDataState, setGridDataState] = useState<GridState>({
        filterState: defaulFilterState(filters),
        sortState: defaultSortState(sortBy, sortByDirection),
        pagingState: defaultPagingState(itemsPerPage),
    });

    const externalFilter = useMemo(() => convertFilter(receivedFilter, primaryKey), [receivedFilter, primaryKey]);

    /*     useEffect(() => {
            if (!gridDataState.dataState) return;
            // filteredIds: an array of all the ids which meet the current filter across all pages
            const filteredIds = process(tableData, gridDataState.dataState).data.map(record => record[primaryKey]);
            notifyDigitalTwin({ filter: { guids: filteredIds } }, eventType.SET_FILTER);
        }, [tableData, gridDataState.dataState]); 
    */

    function onFilterChange(event: GridFilterChangeEvent) {
        if (event.filter)
            cleanFilter(event.filter);
        setGridDataState(prev => ({ ...prev, filterState: event.filter }));
    }

    function onPageChange(event: GridPageChangeEvent) {
        setGridDataState(prev => ({ ...prev, pagingState: event.page }));
    }

    function onSortChange(event: GridSortChangeEvent) {
        setGridDataState(prev => ({ ...prev, sortState: event.sort }));
    }

    function cleanFilter(filter: CompositeFilterDescriptor | FilterDescriptor) {
        if ('operator' in filter) {
            filter.value = filter.value ?? "";
            return;
        }
        filter.filters.forEach(f => cleanFilter(f));
    }

    function convertFilter(dtFilter: DigitalTwinFilter, primaryKey: string): CompositeFilterDescriptor | undefined {
        if (isEmpty(dtFilter?.[primaryKey]))
            return;
        return {
            logic: "or",
            filters: dtFilter?.[primaryKey]?.map((value) => ({
                field: primaryKey,
                operator: 'eq',
                value: value
            })) ?? []
        }
    }

    const sortState = gridDataState.sortState
    const filterState = useMemo(() => ({ internal: gridDataState.filterState, external: externalFilter }), [gridDataState.filterState, externalFilter]);
    const pagingState = gridDataState.pagingState
    return { filterState, pagingState, sortState, onFilterChange, onPageChange, onSortChange }
}