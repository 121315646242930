import baseEditForm from "formiojs/components/_classes/component/Component.form";

const settings = (...extend) => {
    return baseEditForm(
        [{
            key: "display",
            components: [
                {
                    key: "hideLabel",
                    value: "true",
                },
            ]
        }, ...extend]
    );
};

export default settings;