import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export type DeleteModalProps = {
    open: boolean
    onClose: () => void
    onSubmit: () => void
}

export default function DeleteModal({ open, onClose, onSubmit }: DeleteModalProps) {
    return <>
        {open && <Dialog title={"Confirm Delete"} onClose={onClose}>
            <p style={{ margin: "25px", textAlign: "center", }}>
                Are you sure you want to delete this item?
            </p>
            <p style={{ margin: "25px", textAlign: "center", }}>
                Once you delete this item it can not be restored.
            </p>
            <DialogActionsBar>
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={onClose}
                >
                    No
                </button>
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={onSubmit}
                >
                    Yes
                </button>
            </DialogActionsBar>
        </Dialog>}
    </>;
}