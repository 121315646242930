import { useCallback, useEffect, useState } from 'react';
import { embeddedHost } from '../util/embeddedHost'

export const eventType = {
    SET_SELECTION: "DT_Selection",
    SET_NAVIGATION: "DT_Navigation",
    SET_FILTER: "DT_Filter",
    GET_IDENTITY_TOKEN: "DT_GET_IDENTITY_TOKEN",
    SET_IDENTITY_TOKEN: "DT_SET_IDENTITY_TOKEN"
};

export const navigationActions = {
    PREVIOUS_PAGE: "DT_PreviousPage"
}

export const submissionActions = {
    SUBMISSION_SUCCEEDED: "SUBMISSION_Succeeded",
    ASSET_ADDED: "DT_AddTwin"
}

export default function useParentWindowIntegration() {
    const [selections, setSelections] = useState(null);
    const [receivedFilter, setReceivedFilter] = useState(null);

    const messageHandler = useCallback(event => {
        switch (event.data.eventType) {
            case eventType.SET_SELECTION:
                setSelections(event.data.selectedTags || []);
                break;
            case eventType.SET_FILTER:
                setReceivedFilter(event.data.payload)
                break;
            default:
                break;
        }
    }, []);

    // Only gets registered once on mounting and will be removed on unmount
    useEffect(() => {
        window.addEventListener("message", messageHandler);
        return () => window.removeEventListener("message", messageHandler);
    }, [messageHandler]);

    return { selections, receivedFilter, notifyDigitalTwin, notifyAllParentWindows };
}

export function notifyDigitalTwin(message, eventType) {
    window.parent.postMessage(
        {
            ...message,
            eventType
        },
        embeddedHost);
}

export function notifyAllParentWindows(message, eventType) {
    window.parent.postMessage(
        {
            ...message,
            eventType
        },
        "*");
}
