import {useState, useEffect, useMemo, useCallback} from "react";
import { sessionStorageUtility } from '../util/storage';


export default function useSessionState(defaultValue, keys) {
    const key = useMemo(() => keys.join(':'), [keys]);
    const initialValue = useMemo(() => {
        const sessionValue = sessionStorageUtility.getItem(key);

        if (sessionValue){
            try{
                if(sessionValue === "undefined")
                    return undefined;
                return JSON.parse(sessionValue);
            }catch (e){
                console.log("Failed to parse session state:", sessionValue);
            }
        }
        return defaultValue;
    }, [key, defaultValue]);

    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        sessionStorageUtility.setItem(key, JSON.stringify(value));
    }, [key, value]);

    const clearValue = useCallback(()=>{
        sessionStorageUtility.removeItem(key);       
    }, [key]);
    
    return [value, setValue, clearValue];
}

export function useSessionStateEraser(keys){
    const key = useMemo(() => keys.join(':'), [keys]);
    const clearValue = useCallback(()=>{
        sessionStorageUtility.removeItem(key);
    }, [key]);
    return clearValue;
}
