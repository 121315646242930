import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import HistoryIcon from '@material-ui/icons/History';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { process, orderBy } from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Tooltip as KendoTooltip } from "@progress/kendo-react-tooltip";
import isEqual from "lodash/isEqual";
import difference from "lodash/difference";
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import xor from "lodash/xor";
import union from "lodash/union";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridToolbar } from './Toolbar/GridToolbar';
import FormTypes from '../../enums/FormTypes';
import useDigitalTwinIntegration, { eventType } from '../../hooks/parentWindowIntegrationHook';
import { useSearch } from '../../hooks/searchHook';
import { getPassedContextParamString } from '../../util/helpers';
import { useBulkEdit } from './hooks/bulkEditHook';
import { InternalProperties } from './Constants';
import { localStorageUtility } from '../..//util/storage';  

const defaultDataState = (filters) => ({
    sort: [
        { field: "", dir: "asc" }
    ],
    filter: {
        logic: "and",
        filters: [...filters, {
            field: InternalProperties.SHOULD_SHOW,
            operator: 'eq',
            value: true
        }]
    }
});

const defaultPagingState = (itemsPerPage) => ({
    skip: 0,
    take: itemsPerPage
})

const isOptionsValid = (props) => {
    if (props.selectable && props.inlineEditable) {
        throw new Error(`Grid cannot be selectable and inline editable.\n
                        Grid configuration must be updated.\n
                        Grid throwing error: ${props.tableName}`)
    }
}

const useStyles = makeStyles({
    hover: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
});

function DynamicDataGrid(props) {
    //error checking
    isOptionsValid(props)

    let kendoExport;
    let exportColumnSelected = {};
    const { selections: externalSelection, receivedFilter, notifyDigitalTwin } = useDigitalTwinIntegration();

    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState(null);
    const [columnToggleModalOpen, setColumnToggleModalOpen] = useState(false);
    const [columns, setColumns] = useState(props.columns);
    const [gridData, setGridData] = useState(() => buildGridData({
        edits: [],
        values: props.tableData,
        dataState: defaultDataState(props.filters),
        pagingState: defaultPagingState(props.itemsPerPage),
        displayFilter: {},
        selectedIds: []
    }));
    const isAllSelected = !isEmpty(gridData.filteredIds) && difference(gridData.filteredIds, gridData.selectedIds).length === 0;

    const { bulkEditFinish, putGridDataEditEntry, renderers, selection } = useBulkEdit({
        primaryKey: props.primaryKey,
        initialColumns: columns,
        gridData,
        setGridData: (inputObj) => {
            setGridData(buildGridDataMemoized(inputObj))
        },
        onChange: props.onChange
    });

    const [searchResults, searchHandler] = useSearch(gridData.values, props.primaryKey);

    const classes = useStyles();

    function calculateDisplayedRecords(data, dataState) {
        return process(data, dataState).data.map(record => record[props.primaryKey]);
    }

    function buildGridData({ edits, display, dataState, pagingState, values, displayFilter, selectedIds, filteredIds }) {
        const shouldUpdateFilteredIds = !!(values || dataState || displayFilter);

        values = values || gridData.values;
        edits = edits || gridData.edits;
        dataState = dataState || gridData.dataState;
        pagingState = pagingState || gridData.pagingState;

        displayFilter = displayFilter || gridData.displayFilter;
        selectedIds = selectedIds || gridData.selectedIds;

        const shouldShow = (item) => {
            const filtersNotIncludingItem = Object.entries(displayFilter)
                .filter(([field, value]) => !value.includes(item[field]));

            return isEmpty(filtersNotIncludingItem);
        }

        const customDataTransformations = (item) => {
            return columns
                .filter(col => col.filterType === 'date')
                .reduce((transformations, col) => {
                    const attributeValue = item[col.attribute]
                    if (attributeValue && attributeValue instanceof Date) {
                        const dateValue = new Date(attributeValue)
                        dateValue.setHours(0, 0, 0, 0)
                        transformations[col.attribute] = dateValue
                    }
                    return transformations
                }, {})
        }

        const data = values
            .map((item) => ({ ...item, ...customDataTransformations(item) }))
            .map((item) => ({
                ...item,
                [InternalProperties.SELECTED]: selectedIds.includes(item[props.primaryKey]),
                [InternalProperties.SHOULD_SHOW]: shouldShow(item)
            }));
        display = display || process(data, { ...dataState, ...pagingState });

        if (shouldUpdateFilteredIds) {
            filteredIds = calculateDisplayedRecords(data, dataState);
            notifyDigitalTwin({ filter: { guids: filteredIds } }, eventType.SET_FILTER);
        } else {
            filteredIds = gridData.filteredIds
        }

        if (props.sortBy && props.sortBy.value) {
            const sort = {
                field: props.sortBy.value,
                dir: props.sortByDirection || 'asc'
            };
            values = orderBy(values, [sort]);
        }

        // display: the current data shown on the current page of the grid
        // dataState: the filters, sort order and grouping of the grid
        // pagingState: the number of records per page and the number of records to start from
        // values: the data before it is filtered and sorted
        // edits: the edits which have been made of the data in the grid
        // displayFilter: the filter of the grid applied from external sources (ie the search bar or filter events in dt)
        // filteredIds: an array of all the ids which meet the current filter across all pages
        // selectedIds: the currently selected ids
        return { display, dataState, pagingState, values, edits, displayFilter, filteredIds, selectedIds };
    }

    // Memoize buildGridData so we can call it in useEffect and set state without infinite loop
    const buildGridDataRef = useRef(buildGridData);
    const buildGridDataMemoized = useCallback(buildArgs => {
        return buildGridDataRef.current(buildArgs);
    }, []);

    useEffect(() => {
        buildGridDataRef.current = buildGridData;
    });

    useEffect(() => {
        setGridData(buildGridDataMemoized({ edits: [], values: props.tableData, dataState: defaultDataState(props.filters), pagingState: defaultPagingState(props.itemsPerPage) }));
    }, [props.tableData]);

    useEffect(() => {
        const newFilterState = { ...gridData.dataState, filter: { ...gridData.dataState.filter, filters: [...props.filters] } }
        setGridData(buildGridDataMemoized({ edits: [], values: props.tableData, dataState: newFilterState }));
    }, [props.filters])

    useEffect(() => {

        const displayFilter = {
            ...receivedFilter,
            ...(props.searchable && {
                [props.primaryKey]: !!receivedFilter ? intersection(searchResults, receivedFilter[props.primaryKey]) : searchResults
            })
        };

        setGridData(buildGridDataMemoized({ displayFilter }));

    }, [buildGridDataMemoized, props.primaryKey, props.searchable, receivedFilter, searchResults])

    useEffect(() => {
        let columnJson = localStorageUtility.getItem(`${props.accountIdentifier}${props.gridId}`);
        const columnConfig = columnJson ? JSON.parse(columnJson) : columns;

        if (props.enableColumnToggle && columnConfig) {
            setColumns(columnConfig);
        }

    }, [props.columns, props.accountIdentifier, props.enableColumnToggle, props.gridId]);

    useEffect(() => {
        if (props.tableData !== null && props.onChange && props.selectable) {
            let selectedData = props.tableData.filter(data => gridData.selectedIds.includes(data[props.primaryKey]))
            props.onChange(props.enableBulkAdd ? { data: selectedData, primaryKey: props.primaryKey } : selectedData)
        }
    }, [props.onChange, props.tableData, props.primaryKey, props.selectable, gridData.selectedIds]);

    useEffect(() => {
        if (!externalSelection) return;

        updateSelection(externalSelection);
    }, [externalSelection]);


    // HANDLERS
    function handleItemChange(event) {
        event.dataItem[event.field] = event.value;

        gridData.display.data
            .filter(item => item[InternalProperties.INLINE_EDIT])
            .forEach(item => {
                item[event.field] = event.value
            });

        gridData.values
            .filter(item => item[InternalProperties.INLINE_EDIT])
            .forEach(item => {
                item[event.field] = event.value
                putGridDataEditEntry(item[props.primaryKey], event.field, event.value, gridData);
            });

        props.onChange({ Data: gridData.edits })

        setGridData(buildGridDataMemoized({
            display: { ...gridData.display },
            values: gridData.values,
            edits: gridData.edits
        }));
    }

    // This function exports the pdf
    function exportcall() {
        let data = props.tableData.filter(x => {
            return gridData.selectedIds.includes(x[props.primaryKey]);
        })

        let columnsForExport = columns.reduce((res, col) => {
            if (exportColumnSelected[col.key]) {
                const columnField = <ExcelExportColumn field={col.key} title={col.displayName} />;
                res.push(columnField);
            }
            return res;
        }, []);

        kendoExport.save(data, columnsForExport);
        handleModalClose();
    };

    // This function opens the modal and resets/sets up the state of the checkboxes
    function handleExportModalOpen() {
        let selected = {};

        columns.forEach(x => selected[x.key] = false);

        exportColumnSelected = selected;

        setExportModalOpen(true);
    }

    function handleColumnToggleModalOpen() {
        setColumnToggleModalOpen(true);
    }

    // This function closes the modals
    function handleModalClose() {
        setExportModalOpen(false);
        setColumnToggleModalOpen(false);
    }

    function handleExplicitExportClick() {
        let selected = {};
        let exportHeaders = props.explicitExportColumns.split(',');

        columns.forEach((column) => {
            if (exportHeaders.includes(column.displayName)) {
                selected[column.key] = true;
            } else {
                selected[column.key] = false;
            }
        })

        exportColumnSelected = selected;
        exportcall();
    }

    // Called when the grid state is changed (eg column is sorted)
    function handleGridDataStateChange(e) {
        const pagingState = { skip: e.dataState.skip, take: e.dataState.take };

        if (e.dataState.sort === gridData.dataState.sort && e.dataState.filter === gridData.dataState.filter) {
            setGridData(buildGridDataMemoized({ pagingState }));
            return;
        }

        const dataState = { sort: e.dataState.sort, filter: e.dataState.filter };
        setGridData(buildGridDataMemoized({ dataState, pagingState }));
    }

    // Called when the checkbox in the header row is pressed
    function handleSelectAll() {
        if (isEmpty(gridData.filteredIds)) return;
        const selection = isAllSelected ? difference(gridData.selectedIds, gridData.filteredIds) : union(gridData.filteredIds, gridData.selectedIds);
        updateSelection(selection);

        notifyDigitalTwin({ selection: { guids: selection } }, eventType.SET_SELECTION);
    }

    // Changes a single row's selection state
    function handleNewSelection(e) {
        const selectedId = e.dataItem[props.primaryKey];
        const selection = xor(gridData.selectedIds, [selectedId]);
        updateSelection(selection);

        notifyDigitalTwin({ selection: { guids: selection } }, eventType.SET_SELECTION);
    }

    function updateSelection(selected) {
        const newSelection = isEqual(gridData.selectedIds, selected)
            ? []
            : selected;

        setGridData(buildGridDataMemoized({ selectedIds: newSelection }));
    }

    // This function handles the export column selection checkbox being selected
    function handleExportCheckboxClick(index) {
        const shouldExport = exportColumnSelected.hasOwnProperty(columns[index].key)
            ? !exportColumnSelected[columns[index].key]
            : true;

        exportColumnSelected = { ...exportColumnSelected, [columns[index].key]: shouldExport }
    }

    // This function handles the changes to column selection
    function handleColumnToggleCheckboxClick(checkboxIndex) {
        const updatedColumns = columns.map(function (col, colIndex) {
            if (colIndex === checkboxIndex) {
                col.show = !col.show;
            }
            return { ...col }
        });

        localStorageUtility.setItem(`${props.accountIdentifier}${props.gridId}`, JSON.stringify(updatedColumns));
        setColumns(updatedColumns);
    }

    // This function handles the changes to all column selection
    function handleSelectAllColumnToggle() {
        const allSelected = columns.every(col => col.show);
        const updatedColumns = columns.map(col => ({ ...col, show: !allSelected }));

        localStorageUtility.setItem(`${props.accountIdentifier}${props.gridId}`, JSON.stringify(updatedColumns));
        setColumns(updatedColumns);
    }

    // DYNAMIC ELEMENTS

    // This stores the export checkboxes
    const exportColumnCheckboxes = columns.map((element, index) => {
        return (
            <FormControlLabel key={index}
                control={
                    <Checkbox
                        checked={exportColumnSelected[columns[index].key]}
                        onChange={() => handleExportCheckboxClick(index)}
                    />
                }
                label={element.displayName}
            >
            </FormControlLabel>
        );
    });

    // This stores the column toggle checkboxes
    const columnToggleCheckboxes = columns.map((element, index) => {
        return (
            <FormControlLabel key={index}
                control={
                    <Checkbox
                        checked={columns[index].show}
                        onChange={() => handleColumnToggleCheckboxClick(index)}
                    />
                }
                label={element.displayName}
            >
            </FormControlLabel>
        );
    });

    // This stores the body of the export dialog box
    const exportDialogBody = (
        <Dialog open={exportModalOpen} onClose={handleModalClose}>
            <DialogTitle>Select columns to export</DialogTitle>
            <DialogContent>
                <FormGroup row>
                    {exportColumnCheckboxes}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Box width={1} display="flex" justifyContent="space-between" pb={1}>
                    <Button onClick={handleModalClose} variant="contained">Cancel</Button>
                    <Button onClick={exportcall} variant="contained" color="primary">Export</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );

    function onDeleteConfirm(data) {
        const updatedData = {
            [props.deleteOptions.deletedFlagColumn]: true
        }

        const deleteData = {
            tableName: props.entityGroupName,
            entityId: data.dataItem[props.primaryKey],
            data: updatedData
        }

        props.onDeleteClick(deleteData);
        setDeleteModalData(null);
    }

    // This stores the body of the delete dialog box
    const deleteDialogBody = useMemo(() => (
        <Dialog open={!!deleteModalData} onClose={() => setDeleteModalData(null)}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText> Are you sure you want to delete this item?</DialogContentText>
                <DialogContentText> Once you delete this item it can not be restored.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box width={1} display="flex" justifyContent="space-between" pb={1}>
                    <Button onClick={() => setDeleteModalData(null)} variant="contained">Cancel</Button>
                    <Button onClick={() => onDeleteConfirm(deleteModalData)} variant="contained" color="primary">Delete</Button>
                </Box>
            </DialogActions>
        </Dialog>
    ), [deleteModalData, setDeleteModalData, onDeleteConfirm]);


    // This stores the body of the column toggle dialog box
    const columnToggleDialogBody = (
        <Dialog open={columnToggleModalOpen} onClose={handleModalClose}>
            <DialogTitle>Select columns to show</DialogTitle>
            <DialogContent>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={columns.every(col => col.show)}
                                onChange={handleSelectAllColumnToggle}
                            />
                        }
                        label='Select All'
                    >
                    </FormControlLabel>
                </FormGroup>
                <FormGroup row>
                    {columnToggleCheckboxes}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleModalClose} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );

    const textForAllColumns = columns.map((element, index) => {
        return <h5 key={index}>{element.displayName}: {(props.tableData[0] || [])[element.key]}</h5>
    })

    const gridProps = {
        data: gridData.display,

        ...(props.selectable &&
        {
            selectedField: InternalProperties.SELECTED,
            onRowClick: handleNewSelection,
            onSelectionChange: handleNewSelection,
            onHeaderSelectionChange: handleSelectAll,
        }),

        ...(props.inlineEditable &&
        {
            cellRender: renderers.cellRender,
            rowRender: renderers.rowRender,
            editField: InternalProperties.INLINE_EDIT,
            onItemChange: handleItemChange,
        }),

        onDataStateChange: handleGridDataStateChange,

        sortable: props.sortable,
        pageable: props.pageable,
        filterable: props.filterable,
        resizable: props.resizable,
        reorderable: props.reorderable,
        groupable: props.groupable,
        scrollable: "none",
        style: {
            maxHeight: "80vh",
            ...(selection.started &&
            {
                userSelect: "none"
            })
        }
    };

    const shouldRenderToolBar = props.enableExport || props.enableColumnToggle || props.creationButton || props.enableExplicitExport;

    const customHeaderCell = (col) => {
        return (props) => (
            <div className="k-link" onClick={props.onClick}>
                <span title={col.enableTooltip ? col.tooltipContent : undefined}>{props.title}</span>
            </div>
        );
    }

    return (
        <span
            onMouseUp={bulkEditFinish}
            onMouseLeave={bulkEditFinish}>
            {props.textColumns
                ? (
                    <>
                        {textForAllColumns}
                        {props.showCount &&
                            <h5>{props.countLabel}{props.countLabel && ': '}{props.tableData.length}</h5>
                        }
                    </>

                )
                : (
                    <>
                        {props.showCount &&
                            <h5>{props.countLabel}{props.countLabel && ': '}{props.tableData.length}</h5>
                        }

                        {exportDialogBody}
                        {columnToggleDialogBody}
                        {deleteDialogBody}
                        <ExcelExport
                            data={props.tableData}
                            ref={exporter => kendoExport = exporter}
                        >
                        </ExcelExport>

                        {shouldRenderToolBar &&
                            <GridToolbar
                                enableExplicitExport={props.enableExplicitExport}
                                selectable={props.selectable}
                                explicitExportLabel={props.explicitExportLabel}
                                enableColumnToggle={props.enableColumnToggle}
                                enableExport={props.enableExport}
                                handleExportModalOpen={handleExportModalOpen}
                                handleExplicitExportClick={handleExplicitExportClick}
                                handleColumnToggleModalOpen={handleColumnToggleModalOpen}
                                selectedIds={gridData.selectedIds}
                                addContext={props.addContext}
                                creationButtonSettings={props.creationButton}
                                tenant={props.tenant}
                                tableName={props.tableName}
                                onCreateClick={props.onCreateClick}
                                handleSearch={searchHandler}
                                enableGlobalSearch={props.searchable}
                            />

                        }
                        <KendoTooltip openDelay={100} position="right">
                            <Grid
                                {...gridData.dataState}
                                {...gridData.pagingState}
                                {...gridProps}
                            >

                                {props.selectable &&
                                    <GridColumn
                                        locked={true}
                                        field={InternalProperties.SELECTED}
                                        width="70px"
                                        filterable={false}
                                        groupable={false}
                                        resizable={false}
                                        reorderable={false}
                                        headerSelectionValue={isAllSelected}
                                    />}

                                {getColumns()}
                                {getActionButtonsColumn()}

                            </Grid>
                        </KendoTooltip>
                    </>
                )
            }
        </span>
    )

    function getColumns() {
        return columns
            .filter(col => !col.hasOwnProperty("show") || col.show)
            .map(col =>
                <GridColumn
                    key={col.key}
                    field={col.key}
                    title={col.displayName}
                    format={col.format}
                    editor={col.editorType}
                    headerCell={customHeaderCell(col)}
                    filter={col.filterType}
                />
            );
    }

    function getActionButtonsColumn() {
        const doNotRenderColumn = !props.editable && !props.viewable && !props.hasHistory
            && !props.downloadOptions.isDownloadable && !props.deleteOptions.isDeleteable;

        if (doNotRenderColumn) {
            return null;
        }

        const customButton = (data) => {
            return (
                <td className={data.className} style={data.style}>
                    <Box display="flex" justifyContent="space-evenly">
                        {props.editable ?
                            <a href={getPath(data, FormTypes.EDIT, props.editContext)} onClick={() => { onEditClick(data) }} >
                                <EditIcon fontSize="small" />
                            </a>
                            : null}
                        {props.viewable ?
                            <a href={getPath(data, FormTypes.VIEW, props.viewContext)} onClick={() => { onViewClick(data) }}>
                                <VisibilityIcon fontSize="small" />
                            </a>
                            : null}
                        {props.hasHistory ?
                            <a href={getHistoryPath(data)}>
                                <HistoryIcon fontSize="small" />
                            </a>
                            : null}
                        {
                            props.downloadOptions.isDownloadable && (
                                <div className={classes.hover} onClick={() => { onDownloadClick(data) }}>
                                    <GetAppIcon fontSize="small" />
                                </div>
                            )
                        }
                        {
                            props.deleteOptions.isDeleteable && (
                                <div className={classes.hover} onClick={() => { setDeleteModalData(data) }}>
                                    <DeleteIcon fontSize="small" />
                                </div>
                            )
                        }
                    </Box>
                </td>
            )
        }

        return <GridColumn
            width="100px"
            filterable={false}
            groupable={false}
            cell={customButton}
            resizable={false}
            reorderable={false}
            locked={true}
        />

        function getPath(data, type, contextParams) {
            const id = data.dataItem[props.primaryKey];

            return `/${props.tenant}/form/${props.tableName}/${type}/${id}${getPassedContextParamString(contextParams)}`;
        }

        function getHistoryPath(data) {
            const id = data.dataItem[props.primaryKey];
            return `/${props.tenant}/history/${props.entityName}/${id}?table=${props.tableName}`;
        }

        function onEditClick(data) {
            if (props.onEditClick) {
                const id = data.dataItem[props.primaryKey];
                const action = `grid/${props.tableName}/edit`
                props.onEditClick({ id, action });
            }
        }

        function onDownloadClick(data) {
            // default the identifier to the primary key
            const entityIdentifierColumn = props.downloadOptions.entityIdentifierColumn || props.primaryKey;

            const downloadOptions = {
                entityIdentifierColumn: entityIdentifierColumn,
                entityIdentifierValue: data.dataItem[entityIdentifierColumn],
                nameColumn: props.downloadOptions.nameColumn,
                pathColumn: props.downloadOptions.pathColumn,
                tableName: props.entityGroupName
            }

            props.onDownloadClick(downloadOptions)
        }

        function onViewClick(data) {
            if (props.onViewClick) {
                const id = data.dataItem[props.primaryKey];
                const action = `grid/${props.tableName}/view`;
                props.onViewClick({ id, action });
            }
        }
    }
}

DynamicDataGrid.propTypes = {
    tableData: PropTypes.arrayOf(PropTypes.object),
    sortable: PropTypes.bool,
    pageable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    deleteOptions: PropTypes.shape({
        isDeleteable: PropTypes.bool,
        deletedFlagColumn: PropTypes.string
    }),
    downloadOptions: PropTypes.shape({
        entityIdentifierColumn: PropTypes.string,
        isDownloadable: PropTypes.bool,
        pathColumn: PropTypes.string,
        nameColumn: PropTypes.string
    }),
    editable: PropTypes.bool,
    inlineEditable: PropTypes.bool,
    addContext: PropTypes.arrayOf(PropTypes.string),
    editContext: PropTypes.arrayOf(PropTypes.string),
    viewContext: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.arrayOf(PropTypes.object),
    filterable: PropTypes.bool,
    resizable: PropTypes.bool,
    reorderable: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    groupable: PropTypes.bool,
    primaryKey: PropTypes.string.isRequired,
    enableExport: PropTypes.bool,
    enableColumnToggle: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    showCount: PropTypes.bool,
    countLabel: PropTypes.string,
    textColumns: PropTypes.bool,
    creationButton: PropTypes.object,
    tenant: PropTypes.string.isRequired,
    enableBulkAdd: PropTypes.bool,
    searchable: PropTypes.bool,
}

export default DynamicDataGrid
