import { GridCellProps } from '@progress/kendo-react-grid';
import { getPassedContextParamString } from '../../../util/helpers';

type ActionTypes = "index" | "view" | "edit" | "add";

export interface ApiInfo {
    primaryKey: string, 
    tenant: string, 
    tableName: string
}

export interface ContextParams {
    [prop: string]: any
}

export default function getActionPath(data:GridCellProps, type:ActionTypes, context:ContextParams, apiInfo: ApiInfo) {
    const id = data.dataItem[apiInfo.primaryKey];
    const queryString = getPassedContextParamString(context);
    return `/${apiInfo.tenant}/form/${apiInfo.tableName}/${type}/${id}${queryString}`;
}