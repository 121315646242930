import Box from '@material-ui/core/Box';
import isEqual from "lodash/isEqual";
import some from "lodash/some"
import React, { useCallback, useEffect, useState, useContext as useReactContext } from 'react';
import { Form as Formio } from 'react-formio';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import FormTypes from '../../enums/FormTypes';
import useThrowAsyncError from '../../hooks/asyncErrorHook';
import useAssetManagementFetch from '../../hooks/fetchHook';
import useStateLogger from '../../hooks/useStateLogger';
import useNavigation from '../../hooks/navigationHook';
import useBreadCrumbPath from '../../hooks/useBreadCrumbPath';
import { updateSubmissionSuccess } from '../../store/actions/FormActions';
import { urlBuilder } from '../../util/helpers';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Spinner from '../Spinner';
import useContext from './../../hooks/contextHook';
import useFetchPlugin from './Builder/fetchPluginHook';
import componentKeys from "./CustomComponents/constants";
import { getSubComponents, isFormSubmission, isNotFormSubmission, isValidSubmitButton, getAttributesArgument } from './formUtils';
import useFormSubmission from './hooks/formSubmissionHook';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ConfigurationContext } from '../../context/configurationContext';
import { sessionStorageUtility } from '../../util/storage';

export default function Form() {
    const [attributes, setAttributes] = useStateLogger("attributes", useState(null));
    const [dataLoaded, setDataLoaded] = useStateLogger("dataLoaded", useState(false));
    const [submission, setSubmission] = useStateLogger("submission", useState(null));
    const [jsonForm, setJsonForm] = useStateLogger("jsonForm", useState(null));
    const [dataChangeCallbacks, setDataChangeCallbacks] = useStateLogger("dataChangeCallbacks", useState([]));
    const [context] = useState(useContext());
    const [subComponents, setSubComponents] = useStateLogger("subComponents", useState(null));

    const canLoadFormData = jsonForm !== null && attributes !== null && submission === null;
    const isFormLoading = submission === null;

    const accountInfo = useSelector(state => state.user.accountInfo);
    const { breadcrumbProperties } = useBreadCrumbPath();
    const dispatch = useDispatch();
    const { navigationTo, goBack, redirect } = useNavigation();

    const token = accountInfo.jwtIdToken;
    const reactPlugin = useAppInsightsContext();

    const { groupName, formId, assetId, tenant } = useParams();
    const { get, post, put, remove, clearCache } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();
    const plugin = useFetchPlugin({ tableId: jsonForm?.tableId });
    const { featureFlags } = useReactContext(ConfigurationContext);

    const { submitForm } = useFormSubmission({
        tableId: jsonForm && jsonForm.tableId,
        formId,
        submission,
        formComponents: subComponents,
        attributes,
        dataLoaded,
        assetId,
        tenant
    });

    if (!window.Formio.getPlugin(plugin.name) && jsonForm) {
        window.Formio.registerPlugin(plugin.plugin, plugin.name);
    }

    const tenantPath = process.env.NODE_ENV === "development" ? "" : `/${tenant}`
    window.Formio.setApiUrl(`${process.env.REACT_APP_AMM_URL}${tenantPath}`);

    const getValuesFromUrl = useCallback((data) => {
        if (!context.set) return;

        Object.entries(context.set).forEach(([attribute, value]) => {
            const attributeSplit = attribute.split('.');
            if (attributeSplit.length > 1) {
                const lookup = attributeSplit[0];
                const externalProperty = attributeSplit[1];

                const lookupValue = data[lookup] ?? {};
                lookupValue[externalProperty] = value;
                data[lookup] = lookupValue;
            }
            else {
                data[attribute] = value;
            }
        });

    }, [context]);

    useEffect(() => {
        clearCache(); // Clear API cache when switching forms
        sessionStorageUtility.clear(); // Clear session state
    }, [formId, groupName]);

    useEffect(loadFormData,
        [jsonForm, assetId, attributes, formId, get, throwAsyncError, getValuesFromUrl, context, dispatch]);

    useEffect(() => {
        get(`form/config/${groupName}/${formId}Form`)
            .then(data => {
                const base = getSubComponents(data, isNotFormSubmission);
                const forms = getSubComponents(data, isFormSubmission);

                setJsonForm(data);
                setSubComponents({ base, forms });
            })
            .catch(error => throwAsyncError(error));
    }, [formId, groupName, get, throwAsyncError]);

    useEffect(() => {
        if (!jsonForm || !jsonForm.components || !breadcrumbProperties) return;

        var newAttributes = getAttributesArgument(jsonForm, breadcrumbProperties);
        if (isEqual(newAttributes, attributes)) return;

        setAttributes(newAttributes);
    }, [jsonForm, getAttributesArgument, breadcrumbProperties, attributes])

    if (isFormLoading) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            {getFormHeader()}
            <Box mr="10px"> 
                <Formio
                    form={jsonForm}
                    submission={submission}
                    onSubmit={submitForm}
                    onChange={onDataChange}
                    onComponentChange={onComponentChange}
                    options={{
                        onDataChange: onDataChangeSubscribe,
                        accountIdentifier: accountInfo.account.accountIdentifier,
                        jwtToken: accountInfo.jwtIdToken,
                        fetch: { get, post, put, remove }, // Add the fetch hooks in the options, because useAssetManagementFetch hook do not work on the Renderer (TODO : Make it to work on the Renderer)
                        readOnly: formId === FormTypes.VIEW,
                        groupName: groupName,
                        refreshSubmission: () => loadFormData(),
                        tenant: tenant,
                        navigation: { navigationTo, goBack, redirect },
                        token: token,
                        reactPlugin: reactPlugin
                        , featureFlags: featureFlags
                    }} />

            </Box>
        </React.Fragment>
    );

    function onComponentChange({ component }) {
        if (component.type !== "form") return;

        const base = getSubComponents(component, isNotFormSubmission);
        const forms = getSubComponents(component, isFormSubmission);

        setSubComponents({ base, forms });
    }

    function loadFormData() {
        if (canLoadFormData === false) return;

        const formTypeDoesNotRequireData = formId === FormTypes.ADD || formId === FormTypes.INDEX
        if (formTypeDoesNotRequireData) {
            // If the form doesnt need to load any data (example: Index), we set the submission to an empty object.
            setFormData({});
            return;
        }

        const baseUrl = `assets/tables/${jsonForm.tableId}`;
        const pathBuilder = urlBuilder(baseUrl);

        if (assetId) {
            pathBuilder.url = `${pathBuilder.url}/${assetId}`;
        }

        const shouldAddFilters = some(context.filters) && !assetId;
        if (shouldAddFilters) {
            Object.entries(context.filters)
                .forEach(([key, value]) => pathBuilder.query.push(`filters[${key}]=${value}`));
        }

        const shouldAddAttributes = some(attributes);
        if (shouldAddAttributes) {
            const attributesString = attributes.join(",");
            pathBuilder.query.push(`attributes=${attributesString}`);
        }

        let shouldFetch = pathBuilder.url !== baseUrl || some(pathBuilder.query);
        if (shouldFetch) {
            get(pathBuilder.build())
                .then(setFormData)
                .catch(error => throwAsyncError(error));
        }
        else {
            // It will trigger the submissionReady event and help form components to initialise correctly (example: KendoGrid)
            setFormData({});
        }

        function processFormData(data) {
            if (Array.isArray(data) === false || formId === FormTypes.INDEX) {
                setDataLoaded(true);
                return data;
            }

            if (data.length === 1) {
                setDataLoaded(true);
                return data[0];
            }

            const errorMessageComponent = jsonForm.components.find(c => c.key === componentKeys.DataErrorMessageComponentKey);
            if (data.length > 1) {
                const multiDataErrorMessage = errorMessageComponent
                    ? errorMessageComponent.multipleDataErrorMessage
                    : "Multiple data returned";
                throw new Error(multiDataErrorMessage);
            }

            const submitComponent = subComponents.base.find(isValidSubmitButton);
            if (submitComponent && submitComponent.addIfDoesntExist) return {};

            const noDataErrorMessage = errorMessageComponent
                ? errorMessageComponent.noDataErrorMessage
                : "No data returned";
            throw new Error(noDataErrorMessage);
        }

        function setFormData(data) {
            data = processFormData(data)
            getValuesFromUrl(data);
            setSubmission({ data });
            dispatch(updateSubmissionSuccess(data));
        }
    }

    function onDataChangeSubscribe(key, callback) {
        dataChangeCallbacks[key] = callback;
        setDataChangeCallbacks(dataChangeCallbacks);
    }

    function onDataChange(d) {
        Object.values(dataChangeCallbacks).forEach(callback => callback(d))
    }

    function getFormHeader() {
        return (
            <>
                <Box>
                    <Breadcrumb />
                </Box>
            </>
        );
    }
}
