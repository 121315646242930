import { isEqual, union, without } from "lodash";
import { useEffect, useState } from "react";
import { eventType, notifyDigitalTwin } from "../../../hooks/parentWindowIntegrationHook";

interface SelectionState {
    ids: string[]
    isAllSelected: boolean
}

export function useSelection(externalSelection: string[] | null, allIds: string[]) {

    const [selectionState, setSelectionState] = useState<SelectionState>({ ids: externalSelection || [], isAllSelected: false });

    useEffect(() => {
        if (isEqual(selectionState.ids, externalSelection)) return;

        setSelectionState({ ids: externalSelection || [], isAllSelected: false });
    }, [externalSelection])

    useEffect(() => {
        if (isEqual(selectionState.ids, externalSelection)) return;

        notifyDigitalTwin({ selection: { guids: selectionState.ids } }, eventType.SET_SELECTION);
    }, [selectionState])


    function selectId(id: string, isSelected: boolean = true) {
        setSelectionState(prev => ({
            ids: isSelected ? union(prev.ids, [id]) : without(prev.ids, id),
            isAllSelected: false
        }));
    }

    function toggleSelectAll() {
        setSelectionState(prev => ({
            ids: !prev.isAllSelected ? allIds : [],
            isAllSelected: !prev.isAllSelected
        }));
    }

    return { isAllSelected: selectionState.isAllSelected, selectedIds: selectionState.ids, toggleSelectAll, selectId }
}