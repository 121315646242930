import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useState } from 'react';

interface ColumnSelectorProps {
    columns: any[]
    updateColumns: (columns: any[]) => void
}

export default function ColumnSelector(props: ColumnSelectorProps) {

    const [columnToggleModalOpen, setColumnToggleModalOpen] = useState(false);

    // This function handles the changes to column selection
    function handleColumnToggleCheckboxClick(index: number) {
        const updatedColumns = props.columns.map((col, colIndex) => {
            if (colIndex === index) {
                col.show = !col.show;
            }
            return { ...col }
        });

        props.updateColumns(updatedColumns);
    }

    function handleSelectAllColumnToggle() {
        const allSelected = props.columns.every(col => col.show);
        const updatedColumns = props.columns.map(col => ({ ...col, show: !allSelected }));

        props.updateColumns(updatedColumns);
    }

    // This stores the column toggle checkboxes
    const columnToggleCheckboxes = props.columns.map((element, index) => (
        <FormControlLabel 
            key={index}
            control={
                <Checkbox
                    checked={element.show}
                    onChange={() => handleColumnToggleCheckboxClick(index)}
                />
            }
            label={element.displayName}
        />
    ));

    function handleModalClose() {
        setColumnToggleModalOpen(false);
    }

    return (
        <>
            <Dialog open={columnToggleModalOpen} onClose={handleModalClose}>
                <DialogTitle>Select columns to show</DialogTitle>
                <DialogContent>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.columns.every(col => col.show)}
                                    onChange={handleSelectAllColumnToggle}
                                />
                            }
                            label='Select All'
                        />
                    </FormGroup>
                    <FormGroup row>
                        {columnToggleCheckboxes}
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} variant="contained" color="primary">Save</Button>
                </DialogActions>
            </Dialog>
            <Button
                title="Show/Hide Columns"
                variant="contained"
                onClick={() => setColumnToggleModalOpen(true)}
            >
                Columns
            </Button>
        </>
    )
}
