import { CSSProperties } from 'react';

export default function Spinner({ mt = 5, mb = 0 }) {

    const style: CSSProperties = { 
        display: "flex", 
        width: "100%", 
        marginBottom: `${mb * 8}px`,
        marginTop: `${mt * 8}px`,
        justifyContent: "center"
    }

    return (
        <div style={style} >
            <span style={{fontSize: "48px"}} className="k-icon k-i-loading"></span>
        </div>
    );
}