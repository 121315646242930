import { JwtPayload } from "jwt-decode";
import { useApiIdentity } from "./useApiIdentity";
import { useHostIdentity } from "./useHostIdentity";
import { useStorageIdentity } from "./useStorageIdentity";

export type Identity = {
    token: string,
    decoded: JwtPayload & any
}

export function useIdentity() {

    const storageIdentity = useStorageIdentity();
    const hostIdentity = useHostIdentity();
    const apiIdentity = useApiIdentity();

    return getIdentity;

    function getIdentity() {

        return new Promise<Identity>((resolve, reject) => {

            const identity = storageIdentity.getIdentity();

            //look for a valid token in the session storage
            if (identity) {
                resolve(identity)
                storageIdentity.setIdentity(identity);
            }
            else

                //fallback => request a token from the host application
                return hostIdentity.getIdentity()
                    .then((identity) => {
                        storageIdentity.setIdentity(identity);
                        resolve(identity);
                    })
                    //fallback => request a token from an authApi
                    .catch(() => {
                        apiIdentity.getIdentity()
                            .then((identity) => {
                                storageIdentity.setIdentity(identity);
                                resolve(identity);
                            })
                            .catch(reject);
                    });
        });
    };
}

export function reshapeIdentity(identity: Identity) {
    return {
        account: {
            userName: identity.decoded.unique_name,
            name: `${identity.decoded.given_name} ${identity.decoded.family_name}`,
            idToken: { ...identity.decoded },
            idTokenClaims: { ...identity.decoded }
        },
        jwtIdToken: identity.token
    }
}