import * as React from 'react';

export default class Renderers {
    constructor(enterEdit, exitEdit, editFieldName, columns, bulkEditStart, bulkEditHover) {
        this.enterEdit = enterEdit;
        this.exitEdit = exitEdit;
        this.editFieldName = editFieldName;
        this.columnsIds = columns.filter(col => col.options && col.options.isEditable).map(col => col.key);
        this.bulkEditStart = bulkEditStart;
        this.bulkEditHover = bulkEditHover;
    }

    editMode = (td) => {
        const input = td && td.querySelector('input');
        const activeElement = document.activeElement;

        if (!input ||
            !activeElement ||
            input === activeElement ||
            !activeElement.contains(input)) {
            return;
        }

        if (input.type === 'checkbox') {
            input.focus();
        } else {
            input.select();
        }
    }

    cellRender = (tdElement, cellProps) => {
        const dataItem = cellProps.dataItem;
        const cellField = cellProps.field;
        const tdProps = { ...tdElement.props, field: cellField };

        if (this.columnsIds.includes(cellField) == false) {
            tdProps.onClick = () => this.enterEdit(dataItem, undefined);
            return React.cloneElement(tdElement, tdProps, tdElement.props.children);;
        }

        const inEditField = dataItem[this.editFieldName];

        if (cellField && cellField === inEditField) {
            tdProps.ref = this.editMode;
        } else {
            tdProps.onMouseDown = () => this.bulkEditStart(dataItem, cellField);
            tdProps.onMouseEnter = () => this.bulkEditHover(dataItem, cellField); 
            tdProps.onClick = () => this.enterEdit(dataItem, cellField);
        }

        return React.cloneElement(tdElement, tdProps, tdElement.props.children);
    }

    rowRender = (trElement) => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                this.preventExit = true;
                clearTimeout(this.preventExitTimeout);
                this.preventExitTimeout = setTimeout(() => { this.preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(this.blurTimeout);
                if (!this.preventExit) {
                    this.blurTimeout = setTimeout(() => { this.exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(this.blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }
}