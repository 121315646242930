import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import React, { useState } from 'react';
import useThrowAsyncError from '../../hooks/asyncErrorHook';
import useAssetManagementFetch from '../../hooks/fetchHook';
import Spinner from '../Spinner';

const useStyles = makeStyles(() => ({
    property: {
        fontWeight: "bold"
    },
    valueFormatting: {
        margin: "0 0.315rem",
        padding: "0.315rem",
        display: "inline-block",
        wordWrap: "break-word",
    },
    oldValue: {
        backgroundColor: "#EFCFCF",
        textDecoration: "line-through",
        color: "#6E7071",
        boxShadow: "0.315rem 0 0 #EFCFCF, -0.315rem 0 0 #EFCFCF",
    },
    newValue: {
        backgroundColor: "#C2F9D1",
        boxShadow: "0.315rem 0 0 #C2F9D1, -0.315rem 0 0 #C2F9D1",
    }
}));

export default function HistoryDetails({ historyId, columns }) {

    const { get } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();

    const [shouldShowDetails, setShouldShowDetails] = useState(false);
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(false);

    function loadDetails() {
        if (!details) {
            setLoading(true);
            get(`assets/history/${historyId}`)
                .then(data => {
                    setDetails(data);
                    setShouldShowDetails(true);
                    setLoading(false);
                })
                .catch(error => throwAsyncError(error));
        } else {
            setShouldShowDetails(true);
        }
    }

    if (loading) {
        return <Spinner />
    }

    const historyGrid = details &&
        (
            <Box my={3}>
                {details.changedValues.map((change, index) => (<HistoryItem key={index} columns={columns} {...change} />))}
            </Box>
        );

    return (
        <Box width={1}>
            {shouldShowDetails
                ? (<>
                    <Box display="flex" flexDirection="column" mx={3}>
                        {details.modifiedBy && 
                          <>
                            <Box fontWeight="fontWeightMedium">
                                Modified By
                            </Box>
                            <Box>{details.modifiedBy}</Box>
                          </>}
                        {historyGrid}

                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Button onClick={() => setShouldShowDetails(false)}><ArrowUpward /></Button>
                    </Box>
                </>
                )
                : (
                    <Box display="flex" flexDirection="column">
                        <Button onClick={loadDetails}><ArrowDownward /></Button>
                    </Box>
                )}
        </Box>
    );
}

function HistoryItem({ propertyName, oldValue, newValue, columns }) {
    const modifiedColumn = columns.find(columns => columns.value === propertyName);
    const modifiedColumnName = modifiedColumn ? modifiedColumn.text : propertyName;
    const classes = useStyles()

    return (
        <Box mx={2} mb={3}>
            <Typography className={classes.property}>{modifiedColumnName}</Typography>
            {oldValue && <Box my={1}><Typography component="span" className={`${classes.oldValue} ${classes.valueFormatting}`}>{oldValue}</Typography></Box>}
            {newValue && <Typography component="span" className={`${classes.newValue} ${classes.valueFormatting}`}>{newValue}</Typography>}
        </Box>
    )
}