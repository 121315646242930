// This file contains definitions of the action types, grouped by reducers

// Sample
export const UPDATE_IMAGE = "sample/image/update";

//QRCode
export const UPDATE_QRCODE = "qrcode/update";

//USER
export const UPDATE_ACCOUNT_INFO = "user/accountInfo/update";

//Form
export const UPDATE_SUBMISSION = "form/submission/update";