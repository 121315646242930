import { default as React, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAccountInfoSuccess, updateAccountInfoFailure } from '../../store/actions/UserActions';
import commonConstants from '../../util/common';
import { useIdentity, reshapeIdentity } from '../../hooks/useIdentity'
import Spinner from '../Spinner';

export const Authentication = props => {
    const { children } = props
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const getIdentity = useIdentity();

    // On component mounted
    useEffect(() => {
        getIdentity().then(i =>
            dispatch(updateAccountInfoSuccess(reshapeIdentity(i)))
        ).catch(() =>
            dispatch(updateAccountInfoFailure({ errorCode: commonConstants.NOTAUTHENTICATED_ERROR }))
        );
    }, []);

    if (user.authenticated) {
        return children;
    }

    if (user.error) {
        return (
            <div className="login-page-document">
                <div>
                    <span>{commonConstants.NOTAUTHENTICATED_ERROR_MESSAGE}</span>
                </div>
            </div>
        )
    }

    return (
        <div className="authenticating-message">
            <Spinner />
        </div>
    )
}