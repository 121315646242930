const commonConstants = {
    LOGIN_POPUP_ERROR: "popup_window_error",
    LOGIN_POPUP_ERROR_MESSAGE: "Enable popup to continue and refresh the page (F5). Please see the browser address bar section as shown below to enable popup for the asset management portal.",
    LOGIN_POPUP_USERCANCELLED: "user_cancelled",
    LOGIN_POPUP_USERCANCELLED_MESSAGE: "User cancelled the login operation for asset managment. Please refresh the page again and try again",
    AUTHENTICATING_LOGIN_MESSAGE: "Authenticating, please wait...",
    NOTAUTHENTICATED_ERROR: "401",
    NOTAUTHENTICATED_ERROR_MESSAGE: "Unable to log in.  Please refresh the page and try again.",
    UNKNOWN_ERROR_MESSAGE: "Unable to load this item.  Please refresh the page and try again.",
    SITEMAP_LOCAL_STORAGE_KEY: "sitemap",

    DATABASE_PREFIX: "crc5d_",
}

export default commonConstants;
