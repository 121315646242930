export const name = 'FormSubmission'

export default {
    [name]: {
        form: function (ctx) {

            const isPreview = ctx.options.preview || ctx.options.attachMode === "builder";

            if (ctx.component.hidden && !isPreview) {
                ctx.component.hidden = false;
                ctx.component.customClass += ' d-none';
            }

            var previewPadding = isPreview ? 'py-3' : '';

            return `
                <div ref="${ctx.nestedKey}" class="${previewPadding}" ">
                    ${ctx.children}
                </div>
            `;
        }
    }
}