import React from 'react';
import Editor from '../components/Form/Builder/Editor';
import Index from '../components/Form/Builder/Index';
import Create from '../components/Form/Builder/Create';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

export function FormAdminIndexPage() {
    return (
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>
    );
}

export function FormAdminCreatePage({ accountInfo }) {
    return (
        <ErrorBoundary>
            <Create />
        </ErrorBoundary>
    );
}

export function FormAdminEditorPage({ accountInfo }) {
    return (
        <ErrorBoundary>
            <Editor />
        </ErrorBoundary>
    );
}