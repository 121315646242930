import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./KendoGrid.settingsForm";
import RendererV1 from "./Renderer";
import RendererV2 from "./RendererV2";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../../../theme';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';

import {
    IntlProvider,
    load,
  } from "@progress/kendo-react-intl";

import likelySubtags from "cldr-data/supplemental/likelySubtags.json";
import currencyData from "cldr-data/supplemental/currencyData.json";
import weekData from "cldr-data/supplemental/weekData.json";
import nzNumbers from "cldr-data/main/en-NZ/numbers.json";
import nzLocalCurrency from "cldr-data/main/en-NZ/currencies.json";
import nzCaGregorian from "cldr-data/main/en-NZ/ca-gregorian.json";
import nzDateFields from "cldr-data/main/en-NZ/dateFields.json";

load(likelySubtags,
    currencyData,
    weekData,
    nzNumbers,
    nzLocalCurrency,
    nzCaGregorian,
    nzDateFields);

const locale = "en-NZ";

const KendoGridCustomCompBase = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            isVersion2: props.component.isVersion2
        };
    }

    setValue = () => {
        this.setState(
            prevState => ({ ...prevState, value: !prevState.value }),
            () => {
                this.props.onChange(null, this.state.value);
            }

        );
    };

    render() {
        if (!this.props) {
            return null;
        }
        return (
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <IntlProvider locale={locale}>
                        {this.state.isVersion2
                            ? <RendererV2 {...this.props} />
                            : <RendererV1 {...this.props} />
                        }
                    </IntlProvider>
                </ErrorBoundary>
            </ThemeProvider>
        )
    }
};
const KendoGridCustomComp = React.memo(KendoGridCustomCompBase);

export default class KendoGrid extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */

    static get builderInfo() {
        return {
            title: "Grid",
            icon: "table",
            group: "Data",
            documentation: "",
            schema: KendoGrid.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: "kendoGridCustomComp",
            label: "Table",
            isSortable: true,
            isFilterable: false,
            isGroupable: false,
            isPageable: true,
            isResizable: true,
            isReorderable: true,
            isExportable: false,
            isSelectable: false,
            pageSize: 50,
            validate: {
                custom: "valid = true",
            },
            isVersion2: false
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        return ReactDOM.render(
            <KendoGridCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                options={this.options}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }
}