import Button from '@material-ui/core/Button';

export default function Renderer({ component, options }) {
    return (
        <Button color="primary" variant="contained" onClick={downloadFile} disabled={component.disabled}>{component.label}</Button>
    )

    function downloadFile() {
        options.fetch.post(component.customEndpoint, options.root._data)
            .then(data => {
                let link = document.createElement('a');
                link.href = data;
                link.click();
            });
    }
}