import { Typography } from '@material-ui/core';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect } from 'react';

import useThrowAsyncError from '../../../../hooks/asyncErrorHook';
import useSessionState from '../../../../hooks/useSessionState';
import { getNestedProperty, urlBuilder } from '../../../../util/helpers';
import { getSubComponents, isNotFormSubmission } from '../../formUtils';
import { dataTypes } from '../constants';

export default function Renderer({ component, options, onChange }) {
    const initialFilter = component.filterOn && component.filterBy ? getFilterData() : null;

    const [isDisabledForCascade, setDisableForCascade] = useSessionState(!!component.isCascading, [component.key, "isDisabledForCascade"]);
    const [data, setData] = useSessionState(null, [component.key, "data"]);
    const [defaultValue, setDefaultValue] = useSessionState(null, [component.key, "defaultValue"]);
    const [filter, setFilter] = useSessionState(initialFilter, [component.key, "filter"]);

    const throwAsyncError = useThrowAsyncError();

    const cascadeFromComponent = getSubComponents(options.root, isNotFormSubmission)
        .find(comp => comp.component.key === component.cascadeFromApiKey)
        ?.component;

    const checkForCascadeFromChange = useCallback(e => {
        if (e.changed?.component.key === cascadeFromComponent.key) {
            setDefaultValue(null)
        }

        if (isEmpty(cascadeFromComponent.levels)) return;

        const cascadeComponentLevels = cascadeFromComponent.levels;
        const cascadeLookup = cascadeComponentLevels[cascadeComponentLevels.length - 1].lookup;
        const hasCascadeData = options.root._data[cascadeLookup?.navigationProperty];

        setDisableForCascade(!hasCascadeData)
    }, [cascadeFromComponent, options.root._data]);

    useEffect(() => {
        if (!component.isCascading || !cascadeFromComponent) return

        options.events.addListener("formio.change", checkForCascadeFromChange);
        return () => {
            options.events.removeListener("formio.change", checkForCascadeFromChange);
        };
    }, [cascadeFromComponent, checkForCascadeFromChange, component.cascadeFromApiKey, component.isCascading, options.events]);

    if (!component) return null;

    if (component.filterOn && component.filterBy && options.onDataChange) {
        options.onDataChange(component.key, (data, changed, isValid) => {
            const filterData = getFilterData();
            if (filterData !== filter) {
                setFilter(filterData);
                setData(null);
            }

        })
    }

    //Wait for the form submission data to be ready, so the grid understand which context it renders against
    //TODO: Run again the submissionReady event when the user update a lookup (so it refresh the other related lookups)
    options.root.submissionReady.then(onSubmissionReady);

    if (!data) return null;

    const readonlyValue = (defaultValue && defaultValue[getDataKeys().label]) || '-';
    const shouldDisableDropDownList = component.disabled || options.readOnly || component.levels?.length > 1 || isDisabledForCascade

    const dropDownComponent = (component.dropdownType === dataTypes.multiSelect)
        ? <MultiSelect
            data={data}
            dataItemKey={getDataKeys().idColumn}
            defaultValue={defaultValue}
            onChange={handleSelectionChange}
            textField={getDataKeys().label}
            disabled={component.disabled || options.readOnly} />
        : <DropDownList
            data={data}
            dataItemKey={getDataKeys().idColumn}
            defaultValue={defaultValue}
            onChange={handleSelectionChange}
            textField={getDataKeys().label}
            disabled={shouldDisableDropDownList} />;

    return component.disabled
        ? <Typography>{readonlyValue}</Typography>
        : dropDownComponent

    function getDataKeys() {
        if (component.dropdownType === dataTypes.select || component.dropdownType === dataTypes.multiSelect) {
            return {
                idColumn: "Value",
                table: component.selectTable.name,
                label: "Label",
            }
        }
        return {
            ...component.levels[component.levels.length - 1].lookup,
            label: component.displayAttribute.value,
        };
    }

    function getFilterData() {
        return getNestedProperty(options.root.submission.data, `${component.filterBy?.navigationProperty}.id`);
    }

    function onSubmissionReady({ metadata }) {

        //If the submissionReady event is triggered by the submit button, the metadata object is included with associated data. That event should be ignored
        if (metadata && metadata.origin) return;

        getDefaultValue();
        queryData();
    }

    function getDefaultValue() {

        if (!data || defaultValue) {
            return;
        }

        if (component.dropdownType === dataTypes.lookup || !component.dropdownType) {

            let lookup = options.root.submission.data;
            component.levels.forEach(x => {
                if (lookup) {
                    lookup = lookup[x.lookup.navigationProperty];
                }
            });

            let idColumn = getDataKeys().idColumn
            let defaultValue = lookup ? data.find(x => x[idColumn] === lookup[idColumn]) : null;
            setDefaultValue(defaultValue);
            updateSubmisionData(lookup)
            onChange(defaultValue ? defaultValue[getDataKeys().label] : null);
            return;
        }

        if (!options.root.submission.data[component.key]) {
            return;
        }

        if (component.dropdownType === dataTypes.select) {
            const loadedValue = data.find(item => item.Value === options.root.submission.data[component.key]);
            setDefaultValue(loadedValue);
            return;
        }

        if (component.dropdownType === dataTypes.multiSelect) {
            const selectedOptions = options.root.submission.data[component.key].split(",");
            const values = data.filter(item => selectedOptions.includes(item.Value.toString()));
            setDefaultValue(values);
            return;
        }
    }

    function handleSelectionChange(event) {
        let item = event.target.value;
        updateSubmisionData(item);
    }

    function updateSubmisionData(item) {

        if (isEmpty(item)) {
            options.root.submission.data[component.key] = null;
            return;
        }

        if (component.dropdownType === dataTypes.multiSelect) {
            onChange(item.map(selection => selection.Value).join(","));
            return;
        }

        if (component.dropdownType === dataTypes.select) {
            onChange(item.Value.toString());
            return;
        }

        if (item && component.levels.length === 1) {
            let data = options.root.submission.data;
            let lookup = component.levels[0].lookup;

            if (!data[lookup.navigationProperty]) {
                data[lookup.navigationProperty] = {};
            }

            var lookupData = data[lookup.navigationProperty];
            lookupData.table = lookup.table;
            lookupData.id = item[lookup.idColumn];
        }
        onChange(item[getDataKeys().label]);
    }

    function queryData() {
        if (data || options.fetch === undefined) {
            return;
        }

        let pathBuilder = urlBuilder(`assets/tables/`)
        if (component.dropdownType === dataTypes.lookup || !component.dropdownType) {
            pathBuilder.url = `${pathBuilder.url}${getDataKeys().table}`;
            pathBuilder.query.push(`attributes=${getDataKeys().label}`);

            if (component.filterBy && component.filterOn) {
                let filterValue = getNestedProperty(options.root.submission.data, `${component.filterBy.navigationProperty}.id`)

                if (filterValue) {
                    pathBuilder.query.push(`filters[${component.filterOn.navigationProperty}.${component.filterOn.idColumn}]=${filterValue}`);
                }
            }
        } else {
            pathBuilder.url = `${pathBuilder.url}${component.selectTable.name}`
        }

        options.fetch.get(pathBuilder.build())
            .then(data => {

                // Ensure all display fields are strings otherwise the Kendo component breaks eg null
                data.forEach(datapoint => datapoint[getDataKeys().label] = String(datapoint[getDataKeys().label]));

                let sortedData = data.sort((a, b) => {

                    let aDisplayValue = a[getDataKeys().label] ?? "";
                    let bDisplayValue = b[getDataKeys().label] ?? "";

                    // If the display attributes are both numeric strings sort numericly 
                    if (!isNaN(aDisplayValue) && !isNaN(bDisplayValue)) {
                        return parseInt(bDisplayValue) - parseInt(aDisplayValue);
                        // Else sort alphabetically 
                    }

                    return aDisplayValue.localeCompare(bDisplayValue);
                });

                setData(sortedData);
            })
            .catch(error => throwAsyncError(error));
    }
}
