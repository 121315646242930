import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { KeyboardEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface SearchBarProps {
    handleSearch: (query:string) => void
}

const useStyles = makeStyles((theme) => ({
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        maxWidth: "400px"
    },
}));

export default function SearchBar(props: SearchBarProps) {
    const [query, setQuery] = useState("")
    const classes = useStyles();

    function handleKeyPress(event: KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter") {
            props.handleSearch(query);
        }
    }

    return (
        <>
            <TextField
                label="Search"
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                onKeyPress={handleKeyPress}
                placeholder="Search"
                className={classes.input}
            />
            <IconButton onClick={() => props.handleSearch(query)}>
                <SearchIcon />
            </IconButton>
        </>
    )
}