import { UPDATE_ACCOUNT_INFO } from "../ActionTypes";

// STATE
const defaultState = {
    authenticated: false,
    accountInfo: {},
    error: false // { errorCode: commonConstants.[KEY] } // what the 'populated' state of that field will be
}

// REDUCER
export function userReducer(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_ACCOUNT_INFO:
            {
                return {
                    ...state,
                    authenticated: action.payload.authenticated || false,
                    accountInfo: action.payload.accountInfo || {},
                    error: action.payload.error || false
                }
            }
        default:
            {
                return state;
            }
    }
}