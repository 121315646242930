import baseEditForm from "formiojs/components/_classes/component/Component.form";

const settings = (...extend) => {
    return baseEditForm(
        [{
            key: "data",
            components: [
                {
                    type: "textfield",
                    input: true,
                    required: true,
                    label: "Custom Endpoint",
                    labelPosition: "left-right",
                    key: "customEndpoint",
                    tooltip: "This endpoint should be relative to the base api url, and not begin with a forward slash",
                },
                {
                    key: "multiple",
                    ignore: true
                },
                {
                    key: "default",
                    ignore: true
                },
                {
                    key: "persistent",
                    ignore: true
                },
                {
                    key: "protected",
                    ignore: true
                },
                {
                    key: "dbIndex",
                    ignore: true
                },
                {
                    key: "encrypted",
                    ignore: true
                },
                {
                    key: "redrawOn",
                    ignore: true
                },
                {
                    key: "calculateServer",
                    ignore: true
                },
                {
                    key: "allowCalculateOverride",
                    ignore: true
                },
                {
                    key: "clearOnHide",
                    ignore: true
                },
                {
                    key: "customDefaultValuePanel",
                    ignore: true
                },
                {
                    key: "calculateValuePanel",
                    ignore: true
                },
            ]
        }, ...extend]
    );
};

export default settings;