import { ThemeProvider } from '@material-ui/core/styles';
import ButtonComponent from 'formiojs/components/button/Button';
import { Component } from 'react';
import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";

import Renderer from "./Renderer";
import theme from '../../../../theme';
import settingsForm from "./downloadButton.settingsForm";

const DownloadButtonCustomComp = class extends Component {
    render() {
        if (!this.props) {
            return null;
        }
        
        return (
            <ThemeProvider theme={theme}>
                <Renderer {...this.props} />
            </ThemeProvider>
        )
    }
}

export default class CustomButton extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: "Download Button",
            icon: "file",
            group: "Beca",
            documentation: "",
            schema: CustomButton.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema(...extend) {
        return ButtonComponent.schema({
            type: "downloadButtonCustomComp",
            label: "Download",
            theme: "",
            hideLabel: true,
        }, ...extend);
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        return ReactDOM.render(
            <DownloadButtonCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                options={this.options}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }
}