import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import HistoryTimeline from '../components/History/HistoryTimeline';

export default function HistoryPage() {
    const tableName = useQuery().get("table");
    const shouldNotifyDT = useQuery().get("notifydt")?.toLowerCase() === "true";

    const history = useHistory();
    const { tenant } = useParams();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">History</Box>
            {tableName && !shouldNotifyDT &&
                < Box width={1} pr={5} mt={-4} display="flex" justifyContent="flex-end">
                    <Button onClick={() => history.push(`/${tenant}/form/${tableName}/index`)}>Back to Index</Button>
                </Box>}
            <Box width={1} maxWidth={"1000px"}>
                <ErrorBoundary>
                    <HistoryTimeline fullTableName={tableName} shouldNotifyDT={shouldNotifyDT} />
                </ErrorBoundary>
            </Box>
        </Box >
    );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}