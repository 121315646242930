import React, { useState } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import useUriQuery from '../../../../hooks/uriQueryHook';
import { getFilter } from '../../../../util/helpers'

export default function Renderer({ component, options }) {
    const [events, setEvents] = useState(null);
    const uriQuery = useUriQuery();

    if (!component) {
        return null;
    }

    return (
        <React.Fragment>
            <div>
                <Upload
                    autoUpload={component.autoUpload}
                    events={options?.events}
                    batch={component.batch}
                    disabled={component.disabled}
                    multipleFiles={component.multipleFiles}
                    defaultFiles={component.defaultFiles}
                    withCredentials={component.withCredentials}
                    saveField={component.saveField}
                    saveHeaders={{ 'Authorization': `Bearer ${options.token}` }}
                    saveUrl={`${window.Formio.getApiUrl()}/${component.saveUrl}${getFilter(uriQuery)}`}
                    removeUrl={`${window.Formio.getApiUrl()}/${component.removeUrl}`}
                    onStatusChange={onStatusChange}
                    onAdd={onAdd}
                    showActionButtons={component.showActionButtons}
                    responseType={component.responseType}
                />
                {events && <div className={'upload-event'}>
                    <ul className={'event-log'}>
                        {
                            events.map((event, index) =>
                                <li className="fa fa-comments upload-li" key={index}><label className="upload-label">{event}</label></li>
                            )
                        }
                    </ul>
                </div>}

            </div>
        </React.Fragment>
    );

    function onAdd(event) {
        let renderEvents = events;
        renderEvents = [];
        renderEvents.push(`File upload initiated. File selected: (${event.affectedFiles[0].name}). The page will automatically redirect once completed.`);
        setEvents(renderEvents);
    }

    function onStatusChange(event) {
        var ul = document.getElementsByClassName("event-log")[0];
        var li = document.createElement("li");
        var label = document.createElement("label");
        li.className = "fa fa-comments upload-li";
        label.className = "upload-label";
        if (event.response.response.succeeded) {
            label.innerHTML = `File uploaded operation successfully initiated. Will will reach out to once the operation is competed`;
            options.events.emit(`formio.${component.onStatusChange}`);
        }
        else {
            label.innerHTML = `Error uploading file Error: ${event.response.response.error.message}`;
        }
        li.appendChild(label);
        ul.appendChild(li);
    }
}