import { Grid } from '@material-ui/core'
import React from 'react'
import NavCard from '../components/NavCard/NavCard'
import HomePageData from './HomePageData.json'
import Box from '@material-ui/core/Box'
import commonConstants from '../util/common'

export default function HomePage() {

    const navCards = (HomePageData["cards"] || []).map((element) => {
        return (
            <Grid item xs={12} sm={6} m={1} >
                <Box my={1} mr={2}>
                    <NavCard
                        header={element.header}
                        pageLink={element.pageLink.replace(commonConstants.DATABASE_PREFIX, process.env.REACT_APP_DATAVERSE_PREFIX)}
                        description={element.description}
                        linkIsRelative={element.linkIsRelative}
                    />
                </Box>
            </Grid>
        )
    })

    return (
        <Box>
            <h2>{HomePageData["header"]}</h2>
            <Grid container spacing={3}>
                {navCards}
            </Grid>
        </Box>
    );
}
