import AllComponents from 'formiojs/components';

import archieRoseUploadCustomComp from "./ArchieRoseUpload";
import customButtonCustomComp from "./Button";
import cancelButtonCustomComp from './CancelButton';
import dataErrorMessageCustomComp from './DataErrorMessage'
import downloadButtonCustomComp from './DownloadButton'
import formSubmissionCustomComp from "./FormSubmission";
import formSubmissionTemplate from "./FormSubmission/Template";
import kendoGridCustomComp from "./KendoGrid";
import kendoUploadCustomComp from "./KendoUpload";
import lookupCustomComp from "./Lookup";
import qrScannerCustomComp from "./QrScanner";
import toggleCustomComp from "./Toggle";

const All = {
    Components: {
        ...AllComponents,

        //extention of exisitng components
        button: customButtonCustomComp,

        //new custom components
        archieRoseUploadCustomComp,
        kendoGridCustomComp,
        toggleCustomComp,
        kendoUploadCustomComp,
        qrScannerCustomComp,
        lookupCustomComp,
        cancelButtonCustomComp,
        dataErrorMessageCustomComp,
        formSubmissionCustomComp,
    	downloadButtonCustomComp
    },
    Templates: {
        ...formSubmissionTemplate
    }
}

export default All;
