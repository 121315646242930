import Button from '@material-ui/core/Button';
import FormTypes from '../../../enums/FormTypes';
import { getPassedContextParamString } from '../../../util/helpers'

interface CreateButton {
    buttonSettings: any 
    tenant: string 
    addContext: any
    tableName: string 
    onCreateClick: (path:string) => void
}

export default function CreateButton({ buttonSettings, tenant, addContext, tableName, onCreateClick }: CreateButton) {

    const addPath = `/${tenant}/form/${tableName}/${FormTypes.ADD}${getPassedContextParamString(addContext)}`;

    return (
        <a href={addPath} onClick={() => onCreateClick(`grid/${tableName}/create`)}>
            <Button variant="contained" color="primary">{buttonSettings.label}</Button>
        </a>
    );
}
