import baseEditForm from "formiojs/components/_classes/component/Component.form";

import { formioRequestFilter, formioRequestType } from "../../Builder/fetchPluginHook";
import { dataTypes } from '../constants';

const settings = (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        key: "placeholder",
                        ignore: true
                    },
                ]
            },
            {
                key: "data",
                components: [
                    {
                        label: 'Dropdown Type',
                        key: 'dropdownType',
                        input: true,
                        type: 'select',
                        valueProperty: '',
                        defaultValue: "Lookup",
                        data: {
                            values: [
                                {
                                    label: "Lookup",
                                    value: dataTypes.lookup
                                },
                                {
                                    label: "Select",
                                    value: dataTypes.select
                                },
                                {
                                    label: "Multi-Select",
                                    value: dataTypes.multiSelect
                                }
                            ]
                        },
                    },
                    {
                        label: 'Select Table',
                        key: 'selectTable',
                        input: true,
                        type: 'select',
                        dataSrc: 'url',
                        valueProperty: '',
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getSelects}`,
                        },
                        customConditional: "show = data.dropdownType === 'Select' || data.dropdownType === 'Multiselect'",
                        template: '<span>{{ item.name }}</span>',
                    },
                    {
                        label: 'Select Column',
                        key: 'selectColumn',
                        input: true,
                        type: 'select',
                        dataSrc: 'url',
                        valueProperty: '',
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getColumns}&filter=${formioRequestFilter.selectOnly}`,
                        },
                        customConditional: "show = data.dropdownType === 'Select'",
                        template: '<span>{{ item.text }}</span>',
                    },
                    {
                        label: 'Multi-Select Column',
                        key: 'multiSelectColumn',
                        input: true,
                        type: 'select',
                        dataSrc: 'url',
                        valueProperty: '',
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getColumns}&filter=${formioRequestFilter.multiSelectOnly}`,
                        },
                        customConditional: "show = data.dropdownType === 'Multiselect'",
                        template: '<span>{{ item.text }}</span>',
                    },

                    {
                        type: 'datagrid',
                        customConditional: "show = data.dropdownType === 'Lookup'",
                        input: true,
                        label: 'Levels',
                        key: 'levels',
                        reorder: false,
                        addAnother: "Add another lookup level",
                        validate: {
                            customMessage: "Lookups with more than 2 level deeps are not supported",
                            custom: "valid = data.levels.length <= 2 "
                        },
                        components:
                            [
                                {
                                    label: 'Lookup levels',
                                    key: 'lookup',
                                    input: true,
                                    type: 'select',
                                    dataSrc: 'url',
                                    valueProperty: '',
                                    data: {
                                        //This is a fake url query that is meant to be process by the fetchPluginHook
                                        url: `type=${formioRequestType.getColumns}&filter=${formioRequestFilter.lookupOnly}{{rowIndex == 0 ? '' : '&tableName=' + data.levels[rowIndex-1].lookup.table}}`,
                                    },
                                    template: '<span>{{ item.text }}</span>',
                                },
                            ]
                    },
                    {
                        label: 'Display Attribute',
                        key: 'displayAttribute',
                        input: true,
                        type: 'select',
                        dataSrc: 'url',
                        refreshOn: "levels",
                        clearOnHide: true,
                        clearOnRefresh: true,
                        valueProperty: '',
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getColumns}&filter=${formioRequestFilter.noLookup}&tableName={{data.levels[data.levels.length-1].lookup.table}}`,
                        },
                        template: '<span>{{ item.text }}</span>',
                        customConditional: "show = data.dropdownType === 'Lookup' && data.levels[data.levels.length-1].lookup.table",
                        validate: {
                            required: true
                        },
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Filter",
                        key: "filter",
                        tooltip: "Enables the lookup options to be filtered through another form lookup",
                        customConditional: "show = data.dropdownType === 'Lookup' && data.levels[data.levels.length-1].lookup.table",
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Cascading",
                        key: "isCascading",
                        tooltip: "Turns the dropdown into a cascading dropdown",
                        customConditional: "show = data.dropdownType === 'Lookup' && data.levels[data.levels.length-1].lookup.navigationProperty"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Cascade from Api Key",
                        key: "cascadeFromApiKey",
                        tooltip: "Api key of another component in the form for the lookup to cascade from",
                        customConditional: "show = data.isCascading",
                    },
                    {
                        label: 'Filter On Property',
                        key: 'filterOn',
                        input: true,
                        type: 'select',
                        dataSrc: 'url',
                        tooltip: 'This is the property within this lookup\'s table to filter on',
                        valueProperty: '',
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getColumns}&filter=${formioRequestFilter.lookupOnly}{{'&tableName=' + data.levels[data.levels.length-1].lookup.table}}`,
                        },
                        customConditional: "show = data.filter && data.levels[data.levels.length-1].lookup.table",
                        template: '<span>{{ item.text }}</span>',
                    },
                    {
                        label: 'Filter By Property',
                        key: 'filterBy',
                        input: true,
                        type: 'select',
                        dataSrc: 'url',
                        valueProperty: '',
                        tooltip: 'This is the lookup within the form to use as the filter value',
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getColumns}&filter=${formioRequestFilter.lookupOnly}`,
                        },
                        customConditional: "show = data.filter",
                        template: '<span>{{ item.text }}</span>',
                    },
                    {
                        content: "<div style='height: 300px'></div>", //Add an empty space to prevent the above dropdown to overflow out of the modal
                        key: "html",
                        type: "htmlelement",
                        input: false,
                        tableView: false
                    },
                    {
                        key: "multiple",
                        ignore: true
                    },
                    {
                        key: "default",
                        ignore: true
                    },
                    {
                        key: "persistent",
                        ignore: true
                    },
                    {
                        key: "protected",
                        ignore: true
                    },
                    {
                        key: "dbIndex",
                        ignore: true
                    },
                    {
                        key: "encrypted",
                        ignore: true
                    },
                    {
                        key: "redrawOn",
                        ignore: true
                    },
                    {
                        key: "calculateServer",
                        ignore: true
                    },
                    {
                        key: "allowCalculateOverride",
                        ignore: true
                    },
                    {
                        key: "customDefaultValuePanel",
                        ignore: true
                    },
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: []
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};

export default settings;