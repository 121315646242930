import { UPDATE_SUBMISSION } from "../ActionTypes";

// STATE
const defaultState = {
    submission: {},
    error: false
}

// REDUCER
export function formReducer(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_SUBMISSION:
            {
                return {
                    ...state,
                    submission: action.payload.submission || {},
                    error: action.payload.error
                }
            }
        default:
            {
                return state;
            }
    }
}