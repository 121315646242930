import componentKeys from "./CustomComponents/constants";
import formComponentsConfig from './formComponentsConfig';
import { unique } from '../../util/helpers';

export function isFormSubmission(component) {
    return component.type === componentKeys.FormSubmissionComponentKey;
}

export function isNotFormSubmission(component) {
    return isFormSubmission(component) === false;
}

// recursive function to get flattened sub components (to remove nested layout components)
export function getSubComponents(formData, componentFilter = () => true) {
    if (!formData.components && formData.type !== formComponentsConfig.columns.type) {
        return [];
    }

    const components = formData.type === formComponentsConfig.columns.type
        ? formData.columns.flatMap(x => x.components)
        : formData.components;

    return components
        .filter(componentFilter)
        .flatMap((component) => [component, ...getSubComponents(component, componentFilter)]);
}


export function isValidSubmitButton(component) {
    return component.type === formComponentsConfig.submitButton.type
        && component.action === formComponentsConfig.submitButton.action
        && !component.hidden
        && !component.disabled;
}

// Use the array of form componenents (inside formData) to detect the needed fields and generate a list of attributes to query
export function getAttributesArgument(formData, breadcrumbProperties) {
    if (!formData || !formData.components) {
        return [];
    }

    const allAttributes = getSubComponents(formData, isNotFormSubmission)
        .reduce((acc, val) => {
            const relatedComponent = formComponentsConfig[val.type];
            if (relatedComponent && relatedComponent.attributeField && !val.protected) {
                acc.push(relatedComponent.attributeField(val));
            }
            return acc;
        }, []);

    return !!breadcrumbProperties
        ? unique(allAttributes.concat(breadcrumbProperties))
        : unique(allAttributes);
}