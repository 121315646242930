import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./ArchieRoseUpload.settingsForm";
import Renderer from "./Renderer";

const ArchieRoseUploadCustomComp = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    setValue = () => {
        this.setState(
            prevState => ({ value: !prevState.value }),
            () => this.props.onChange(null, this.state.value)
        );
    };

    render() {
        if (!this.props) {
            return null;
        }
        return <Renderer {...this.props} />
    }
};

export default class ArchieRoseUpload extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: "Archie Rose Upload",
            icon: "file",
            group: "Beca",
            documentation: "",
            schema: ArchieRoseUpload.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: "archieRoseUploadCustomComp",
            label: "Archie Rose Upload",
            autoUpload: false,
            batch: false,
            disabled: false,
            responseType: "json",
            multipleFiles: false,
            withCredentials: false,
            saveField: "File",
            saveUrl: "",
            removeUrl: ""
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        return ReactDOM.render(
            <ArchieRoseUploadCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                options={this.options}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }
}