import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import formTypes from '../../../enums/FormTypes';
import useThrowAsyncError from '../../../hooks/asyncErrorHook';
import useAssetManagementFetch from './../../../hooks/fetchHook';
import AddFormGroup from './AddFormGroup';
import { useStyles } from './Styles';

export default function Create() {
    const classes = useStyles();
    const [tableId, setTableId] = useState('');
    const [formType, setFormType] = useState('');
    const [tables, setTables] = useState([]);
    const [formGroups, setFormGroups] = useState([]);
    const [selectedFormGroup, setSelectedFormGroup] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();
    const { tenant } = useParams();

    const { get, post } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();

    useEffect(() => {
        get("form/schema/tables")
            .then(data => setTables(data))
            .catch(error => throwAsyncError(error));

        get("admin/form/formgroups")
            .then(data => setFormGroups(data))
            .catch(error => throwAsyncError(error));

    }, [get, throwAsyncError])

    return (
        <>

            <AddFormGroup
                key={tableId}
                preSelectedTable={tableId}
                isModalOpen={modalOpen}
                handleModalClose={handleModalClose}
                currentFormGroups={formGroups.map(group => group.name)}
            />

            <Box m={2}>
                <Typography variant="h3">Create new form</Typography>

                <Box display="flex" alignItems="center">
                    <Box width={200}>
                        Select a table:
                </Box>
                    <Box width={200}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel>Table</InputLabel>
                            <Select
                                disabled={!tables}
                                value={tableId}
                                onChange={changeTableId}
                                label="tableId"
                            >
                                {(tables || []).map(table =>
                                    <MenuItem key={table.text} value={table.value.name}>{table.text}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box display="flex" alignItems="center">
                    <Box width={200}>
                        Select a form group:
                </Box>
                    <Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel>Form Group</InputLabel>
                            <Select
                                disabled={!(formGroups && tableId)}
                                value={selectedFormGroup}
                                onChange={changeSelectedFormGroup}
                                label="formgroupname"
                            >
                                {(formGroups || []).filter(formGroup => formGroup.table === tableId)
                                    .map(formGroupForCurrentTable =>
                                        <MenuItem key={formGroupForCurrentTable.name} value={formGroupForCurrentTable.name}>{formGroupForCurrentTable.name}</MenuItem>
                                    )}

                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <Button disabled={!(formGroups && tableId)} variant="contained" color="primary" onClick={() => setModalOpen(true)}>Add</Button>
                    </Box>
                </Box>

                <Box display="flex" alignItems="center">
                    <Box width={200}>
                        Select a form type:
                </Box>
                    <Box width={200}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                disabled={!selectedFormGroup}
                                value={formType}
                                onChange={changeFormType}
                                label="formType"
                            >
                                <MenuItem value={formTypes.ADD}>Add</MenuItem>
                                <MenuItem value={formTypes.EDIT}>Edit</MenuItem>
                                <MenuItem value={formTypes.INDEX}>Index</MenuItem>
                                <MenuItem value={formTypes.VIEW}>View</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box className={classes.buttons}>
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`/${tenant}/admin/form/index`}
                    >
                        Cancel
                </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!tableId || !formType}
                        onClick={createForm}
                    >
                        Create
            </Button> (if the form already exists, you will be redirected to it)
            </Box>

            </Box >
        </>
    );

    function handleModalClose() {
        get("admin/form/formgroups")
            .then(data => {
                setFormGroups(data)
            })
            .catch(error => throwAsyncError(error));

        setModalOpen(false);
    }
    function changeTableId(event) {
        setTableId(event.target.value);
    };

    function changeSelectedFormGroup(event) {
        setSelectedFormGroup(event.target.value);
    }
    function changeFormType(event) {
        setFormType(event.target.value);
    };

    function createForm() {
        if (tableId && formType) {
            let body = { display: "form", tableId: tableId, components: [] };

            switch (formType) {
                case formTypes.VIEW:
                case formTypes.INDEX:
                    //add a default component to prevent the automatic creation of a submit button
                    body.components.push(
                        {
                            type: "textfield",
                            label: "First Field"
                        }
                    );
                    break;
                default:
                    break;
            }

            post(`admin/form/${selectedFormGroup}/${formType}Form`, body)
                .then(() => history.push(`/${tenant}/admin/form/edit/${selectedFormGroup}/${formType}`))
                .catch(error => throwAsyncError(error));
        };
    }
}
