import baseEditForm from "formiojs/components/_classes/component/Component.form";

const settings = (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        key: "placeholder",
                        ignore: true
                    },
                ]
            },
            {
                key: "data",
                components: [
                    {
                        type: 'textfield',
                        input: true,
                        weight: -10,
                        label: "Save Url",
                        key: "saveUrl",
                        tooltip: "Sets the URL of the endpoint for the upload request. The requested FormData key is named after the saveField property and contains the list of files that will be uploaded."
                    },
                    {
                        type: 'textfield',
                        input: true,
                        weight: -10,
                        label: "Remove Url",
                        key: "removeUrl",
                        tooltip: "Sets the URL of the endpoint for the remove request. The FormData request key is named after the removeField property. It contains the list of file names which will be removed."
                    },
                    {
                        type: 'textfield',
                        input: true,
                        weight: -10,
                        label: "On Status Change",
                        key: "onStatusChange",
                        tooltip: "Fires when the status of the files is changed. Optionally, if a request is made, can contain a server response."
                    },
                    {
                        type: 'textfield',
                        input: true,
                        weight: -10,
                        label: "Response Type",
                        key: "responseType",
                        tooltip: "Sets the expected response type of the server. Used to parse the response appropriately. Defaults to json.",
                        defaultValue: "json"
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Auto Upload?",
                        key: "autoUpload",
                        tooltip: "By default, the selected files are immediately uploaded. To change this behavior, set autoUpload to false.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Show Action Button",
                        key: "showActionButtons",
                        tooltip: "When the autoUpload option is set to false, showActionButtons toggles the visibility of the action buttons which are rendered.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Batch?",
                        key: "batch",
                        tooltip: "The Upload allows the upload of the selected files in separate requests through the default configuration of its batch option to false. If you set batch to true, all selected files will be uploaded in a single request.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Disabled?",
                        key: "disabled",
                        tooltip: "Disables the Upload.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Multiple Files?",
                        key: "multipleFiles",
                        tooltip: "The Upload allows the selection of multiple files through the default configuration of its multiple option to true. If you set multiple to false, the selection of files will be limited to a single file.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "With Credentials?",
                        key: "withCredentials",
                        tooltip: "Configures whether credentials (cookies, headers) will be sent for cross-site requests. Defaults to true. Setting withCredentials has no effect on same-site requests. To add credentials to the request, use the saveHeaders or removeHeaders property.",
                        defaultValue: false
                    },
                    {
                        type: 'textfield',
                        input: true,
                        weight: -10,
                        label: "Save Field",
                        key: "saveField",
                        tooltip: "Sets the FormData key which contains the files submitted to saveUrl.",
                        defaultValue: "File"
                    }
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: []
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};

export default settings;