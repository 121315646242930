import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import loggerMiddleware from './middleware/logger'
import monitorReducersEnhancer from './enhancers/monitorReducers'
import createRootReducer from './reducers/rootReducer'

export default function configureStore(history, preloadedState) {

    //Middleware to run in all environments
    let middlewares = [thunkMiddleware]

    //Middleware that should only run during development
    if (process.env.NODE_ENV !== 'production') {
        middlewares = middlewares.concat([loggerMiddleware]);
    }
    const middlewareEnhancer = applyMiddleware(...middlewares)

    //Enhancers to run on all environments 
    let enhancers = [middlewareEnhancer]

    //Enhancers that should only run during development
    if (process.env.NODE_ENV !== 'production') {
        enhancers = enhancers.concat([monitorReducersEnhancer]);
    }

    const composedEnhancers = composeWithDevTools(...enhancers)

    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composedEnhancers
    )

    return store
}