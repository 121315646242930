import { Button } from '@material-ui/core';
import React from 'react';


export default function BackButton(props) {
    const { callback, ...styleProps } = props;

    function onButtonClick() {
        window.history.back();
        if (callback) {
            callback();
        }
    }

    return (
        <Button {...styleProps} onClick={onButtonClick}>
            <div>
                Go Back <i className="fa fa-arrow-left ml-1"></i>
            </div>
        </Button>
    )
}