import { useMemo } from "react";
import { dataTypes } from "../components/Form/CustomComponents/constants";
import { FilterData, OptionsData } from "../types";

export function useFilters(filterData: FilterData, options: OptionsData, columns: any[]) {

    const filters = useMemo(() => 
        getFilter(filterData, options, columns), 
        [filterData, options, columns]);

    function getFilter(filterData: FilterData, options: OptionsData, columns: any[]) {
        if (!filterData.valueFromApiKey && !filterData.value) return [];
    
        var filterValue = filterData.valueFromApiKey
            ? options.root._data[filterData.value]
            : filterData.value;
    
        // If column is boolean type convert filterValue to bool instead of string
        const filterDataColumnType = columns.find(col => col.property.value === filterData.column).property.type;
        if (filterDataColumnType === dataTypes.boolean) {
            filterValue = filterValue.toLowerCase() === 'true';
        }
    
        return [{ field: filterData.column, operator: "equals", value: filterValue }];
    }

    return { filters }
}