import baseEditForm from "formiojs/components/_classes/component/Component.form";

import { formioRequestType } from "../../Builder/fetchPluginHook"
import { kendoUploadMetadataColumnTypes } from "../constants"

const settings = (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        key: "placeholder",
                        ignore: true
                    },
                ]
            },
            {
                key: "data",
                components: [
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Auto Upload?",
                        key: "autoUpload",
                        tooltip: "By default, the selected files are immediately uploaded. To change this behavior, set autoUpload to false.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Show Action Button",
                        key: "showActionButtons",
                        tooltip: "When the autoUpload option is set to false, showActionButtons toggles the visibility of the action buttons which are rendered.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Batch?",
                        key: "batch",
                        tooltip: "The Upload allows the upload of the selected files in separate requests through the default configuration of its batch option to false. If you set batch to true, all selected files will be uploaded in a single request.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Disabled?",
                        key: "disabled",
                        tooltip: "Disables the Upload.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "Multiple Files?",
                        key: "multipleFiles",
                        tooltip: "The Upload allows the selection of multiple files through the default configuration of its multiple option to true. If you set multiple to false, the selection of files will be limited to a single file.",
                        defaultValue: false
                    },
                    {
                        type: "checkbox",
                        input: true,
                        weight: -10,
                        label: "With Credentials?",
                        key: "withCredentials",
                        tooltip: "Configures whether credentials (cookies, headers) will be sent for cross-site requests. Defaults to true. Setting withCredentials has no effect on same-site requests. To add credentials to the request, use the saveHeaders or removeHeaders property.",
                        defaultValue: false
                    },
                    {
                        type: "textfield",
                        input: true,
                        weight: -10,
                        label: "Storage Folder",
                        key: "storageLocation",
                        tooltip: "the folder in blob storage to store the uploaded files",
                        defaultValue: "attachments"
                    },
                    {
                        type: "textfield",
                        input: true,
                        weight: -10,
                        label: "Event To Emit On Upload",
                        key: "uploadCompleteEvent",
                        tooltip: "the formio event to emit on upload",
                        defaultValue: ""
                    },
                    {
                        type: "select",
                        input: true,
                        label: "Allowed File Extensions",
                        key: "allowedExtensions",
                        dataSrc: "url",
                        data: {
                            url: 'storage/file/supportedFileTypes',
                        },
                        multiple: true,
                        valueProperty: "",
                        weight: -10,
                        template: "<span>{{ item }}</span>",
                    },
                    {
                        type: "select",
                        input: true,
                        weight: -10,
                        label: "Metadata Table",
                        placeholder: "Table",
                        key: "metadataTable",
                        tooltip: "The table that holds metadata associated with the uploads",
                        dataSrc: "url",
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getTables}`,
                        },
                        valueProperty: "value",
                        template: "<span>{{ item.text }}</span>",
                    },
                    {
                        type: "datagrid",
                        input: true,
                        label: "Columns",
                        key: "metadataProperties",
                        reorder: true,
                        defaultValue: [{ label: "", value: "" }],
                        weight: -10,
                        conditional: {
                            show: false,
                            when: "metadataTable",
                            eq: "",
                        },
                        components: [
                            {
                                label: "Property",
                                key: "PropertyWell",
                                type: "well",
                                input: false,
                                tableView: false,
                                components: [
                                    {
                                        label: "Property",
                                        key: "property",
                                        hideLabel: true,
                                        input: true,
                                        type: "select",
                                        placeholder: "Column",
                                        dataSrc: "url",
                                        data: {
                                            //This is a fake url query that is meant to be process by the fetchPluginHook
                                            url: `type=${formioRequestType.getColumns}&tableName={{data.metadataTable.name}}`,
                                        },
                                        valueProperty: "",
                                        template: "<span>{{ item.text }}</span>",
                                    },
                                    {
                                        label: "Value Type",
                                        key: "valueType",
                                        hideLabel: true,
                                        input: true,
                                        type: "select",
                                        placeholder: "Value Type",
                                        dataSrc: "values",
                                        customConditional: "show = row.property !== ''",
                                        data: {
                                            values: [
                                                { value: kendoUploadMetadataColumnTypes.CONTEXT, label: "From Context" },
                                                { value: kendoUploadMetadataColumnTypes.CUSTOM, label: "Custom" },
                                                { value: kendoUploadMetadataColumnTypes.PATH, label: "Path Column" },
                                                { value: kendoUploadMetadataColumnTypes.NAME, label: "Name Column" }
                                            ]
                                        },
                                        valueProperty: "value",
                                        template: "<span>{{ item.label }}</span>",
                                    },
                                    {
                                        label: "Value",
                                        key: "value",
                                        hideLabel: true,
                                        input: true,
                                        type: "textfield",
                                        customConditional: "show = row.valueType === 'custom'"
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: []
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};

export default settings;