import { useState } from "react"

// Hook signature:
// const [ searchHandler, searchResults ] = useSearch(nonSearchedData)

export const useSearch = (entireSet, idField) => {
    const [searchResults, setSearchResults] = useState(entireSet.map(record => record[idField]));

    const searchHandler = (query) => {
        const cleanedQuery = query.toLowerCase().trim()
        if (!cleanedQuery) {
            setSearchResults(entireSet.map(record => record[idField]));
            return;
        }
        const queryResult = entireSet
            .filter(record => Object.values(record).some(value => value?.toString().toLowerCase().includes(cleanedQuery)))
            .map(record => record[idField]);
        setSearchResults(queryResult)
    }

    return [searchResults, searchHandler];
}