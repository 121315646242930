import React from 'react';
import some from "lodash/some"

import FormTypes from '../../../enums/FormTypes';
import useThrowAsyncError from '../../../hooks/asyncErrorHook';
import useAssetManagementFetch from '../../../hooks/fetchHook';
import useNavigation from '../../../hooks/navigationHook';
import { urlBuilder } from '../../../util/helpers';
import useParentWindowIntegration, { eventType, submissionActions } from './../../../hooks/parentWindowIntegrationHook';
import componentKeys from "./../CustomComponents/constants";
import { isValidSubmitButton, getAttributesArgument } from './../formUtils';
import { cloneDeep } from 'lodash';

export default function useFormSubmission({ submission, formComponents, tableId, attributes, formId, dataLoaded, assetId, tenant }) {

    const { post, put } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();
    const { notifyDigitalTwin, notifyAllParentWindows } = useParentWindowIntegration();
    const { navigationTo, redirect } = useNavigation();

    return {
        submitForm
    }

    function isKendoGrid(component) {
        return component.type === componentKeys.KendoGridComponentKey
            && !component.protected
            && !component.hidden;
    }

    function submitForm() {
        const kendoGrids = formComponents.base.filter(isKendoGrid)

        var pendingRequests = [
            secondaryFormSubmissions(),
            bulkInLineEditGrids(kendoGrids)
        ];

        Promise.all(pendingRequests) //wait for all other requests
            .then(() => {
                const bulkAddGrids = kendoGrids.filter(x => x.enableBulkAdd && x.bulkAddTable.text);
                if (some(bulkAddGrids)) {
                    bulkInLineAddGrids(bulkAddGrids)
                        .then(submissionRedirect);
                } else {
                    normalSubmission();
                }
            });
    }

    function notifyOfSuccessfulSubmission() { notifyAllParentWindows({ succeeded: true }, submissionActions.SUBMISSION_SUCCEEDED) };

    async function normalSubmission() {
        const submissionSettings = formComponents.base.find(isValidSubmitButton);
        const hasCustomSubmission = submissionSettings && submissionSettings.customApi;

        const path = hasCustomSubmission
            ? submissionSettings.customEndpoint
            : `assets/tables/${tableId}`;

        const data = hasCustomSubmission
            ? submission
            : tidySubmissionData(attributes);

        const formatedData = fixDataFormat(data);

        const shouldPostData = formId === FormTypes.ADD || submissionSettings && submissionSettings.addIfDoesntExist && !dataLoaded

        if (shouldPostData) {
            try {
                const response = await post(`${path}`, formatedData);
                notifyAllParentWindows({ ...formatedData, ...response }, submissionActions.ASSET_ADDED);
                notifyOfSuccessfulSubmission();
                submissionRedirect(response);
            } catch (error) {
                throwAsyncError(error);
            }
        } else {
            const id = assetId || submission.data["_assetId"];
            if (!id) submissionRedirect();
            try {
                const response = await put(`${path}/${id}`, formatedData);
                notifyOfSuccessfulSubmission();
                submissionRedirect(response);
            } catch (error) {
                throwAsyncError(error);
            }
        }
    }

    function fixDataFormat(data) {
        var formatedData = cloneDeep(data);

        formComponents.base.filter(component => Object.keys(formatedData).some(key => key === component.key))
            .forEach(component => {
                if (component.dataType === "number") {
                    formatedData[component.key] = parseFloat(formatedData[component.key]);
                }

                // More handlers for data fix can be added here
            });

        return formatedData;
    }

    async function secondaryFormSubmissions() {
        return await Promise.all(formComponents.forms
            .filter(item => !item.protected)
            .map(async formSubmission => {
                const formSubmissionAttributes = getAttributesArgument(formSubmission);
                const data = tidySubmissionData(formSubmissionAttributes, formSubmission.subPrefix);
                const formSubmissionAssetId = formSubmission.assetIdField && submission.data[formSubmission.assetIdField];

                if (formSubmissionAssetId) {
                    await put(`assets/tables/${formSubmission.submissionTable}/${formSubmissionAssetId}`, data)
                        .catch(error => throwAsyncError(error));
                } else {
                    await post(`assets/tables/${formSubmission.submissionTable}`, data)
                        .catch(error => throwAsyncError(error));
                }
            }));
    }

    async function bulkInLineEditGrids(kendoGrids) {
        return await Promise.all(kendoGrids
            .filter(x => x.isInlineEditable)
            .map(async editGrid => {
                let editData = submission.data[editGrid.key]

                if (!editData) return;

                await put(`assets/tables/${editGrid.tableSelected.name}/bulk`, editData)
                    .catch(error => throwAsyncError(error));
            }));
    }

    async function bulkInLineAddGrids(addGrids) {
        addGrids.forEach(async grid => {

            const gridSubmission = submission.data[grid.key];

            if (!gridSubmission?.data) return;

            if (grid.bulkAddTable.text === grid.tableSelected.name) {
                grid.sameTableSubmission = true;
            }

            let addData = gridSubmission.data.map(selectedRow => {
                let submissionRowObject = grid.sameTableSubmission ? selectedRow : {};

                if (grid.addPrimaryKey) {
                    submissionRowObject[grid.primaryKeyColumn] = {
                        table: grid.tableSelected.name,
                        id: selectedRow[gridSubmission.primaryKey]
                    };
                }

                if (grid.addAssetId) {
                    submissionRowObject[grid.assetIdColumn] = {
                        table: tableId,
                        id: assetId
                    };
                }

                return submissionRowObject;
            });

            grid.extraFields.forEach(extraField => {
                addData.forEach(selectedRow => {
                    if (extraField.submissionColumn.type === "Lookup") {
                        selectedRow[extraField.submissionColumn.navigationProperty] = {
                            id: submission.data[extraField.apiKey],
                            table: extraField.submissionColumn.table
                        };
                    } else {
                        selectedRow[extraField.submissionColumn.value] = submission.data[extraField.apiKey];
                    }
                });
            });

            await post(`assets/tables/${grid.bulkAddTable.text}/bulk`, addData)
                .catch(error => throwAsyncError(error));
        });
    }

    /**
     * Build a clean data object from the submission.data that only contains the properties we need
     * This it to make sure we don't post unneeded properties from the submission
     * Example (buttons, some layout objects, tables, @odata properties, etc)
     **/
    function tidySubmissionData(attributes, prefix) {
        return attributes.map(x => x.split(".")[0]) // get the first part of lookups
            .reduce((acc, val) => { // get the submission data for each attribute
                const filteredKey = val.replace(new RegExp(`^${prefix || ''}`), '');
                acc[filteredKey] = submission.data[val];
                return acc;
            }, {});
    }

    function submissionRedirect(data) {
        const submitComponent = formComponents.base.find(isValidSubmitButton);
        const locationToRedirect = submitComponent.redirectTo ?? formId;
        const transferContext = submitComponent.transferContext;

        redirect({ locationToRedirect, transferContext, customRedirectCallback: customRedirection })

        function customRedirection() {
            let baseRedirectionUrl = `/${tenant}/form/${submitComponent.redirectionLocation}`;
            if (submitComponent.redirectionIdApiKey) baseRedirectionUrl += `/${submission.data[submitComponent.redirectionIdApiKey]}`
            const pathBuilder = urlBuilder(baseRedirectionUrl);

            submitComponent.formRedirectionFields.forEach(field => {
                const redirectionValue = field.redirectionOverrideValue || submission.data[field.redirectionContextData] || (data && data[field.redirectionContextData]);
                if (redirectionValue != null) {
                    pathBuilder.addQuery(`${field.redirectionContextType}[${field.redirectionParameterName}]`, `${redirectionValue}`);
                }
            });

            const primaryKey = getPrimaryKey();
            if (some(primaryKey)) {
                notifyDigitalTwin({
                    tag: primaryKey,
                    action: `form/${submitComponent.redirectionLocation}`
                }, eventType.SET_NAVIGATION)
            }

            navigationTo({ pathBuilder }).go();
        }

        function getPrimaryKey() {
            const primaryKeyRedirectionField = submitComponent.formRedirectionFields
                .find(field => field.redirectionPrimaryKey === true);

            return primaryKeyRedirectionField
                ? (primaryKeyRedirectionField.redirectionOverrideValue || submission.data[primaryKeyRedirectionField.redirectionContextData] || data[primaryKeyRedirectionField.redirectionContextData])
                : ""
        }
    }
};